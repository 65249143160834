<template>
  <router-view></router-view>
  <notifications
    position="top right"
    classes="vue-notification"
    :pauseOnHover="true"
    style="z-index: 99999999;margin-top: 50px !important;"
  />
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
v
