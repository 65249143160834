<template>
  <div v-show="isShowModal">
    <div class="sds-modal h-100 justify-content-center">
      <div class="sds-modal-body-new mx-auto">
        <div class="modal-header pt-0">
          <!-- <p class="modal-title">ORDER BOOK</p> -->
          <button type="button" class="close" v-on:click="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="container form-signin3">
          <div class="row">
            <div
              class="col-md-12"
              style="padding-left: 50px; padding-right: 50px"
            >
              <!-- <form class="form-inline transparent-form p10 border-page m-t-2">
                <div class="col-lg-12 row">
                  <div class="col-lg-10 row">
                    <div class="input-group mb-2 mr-sm-2"> -->
              <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div> -->
              <!-- <datepicker
                        v-model="fromDate"
                        :upper-limit="toDate"
                        class="form-control"
                        placeholder="From Date"
                      />
                    </div>
                    <div class="input-group mb-2 mr-sm-2"> -->
              <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div> -->
              <!-- <datepicker
                        v-model="toDate"
                        :lower-limit="fromDate"
                        class="form-control"
                        placeholder="To Date"
                      />
                    </div> -->
              <!-- <div
                      class="col-lg-2 input-group mb-2 mr-sm-2 custom-control custom-radio d-block float-left"
                    >
                      <Field
                        v-model="order_type"
                        type="radio"
                        value="PENDING"
                        id="gross_partnership"
                        name="net_gross_partner"
                        v-on:change="getList()"
                        class="custom-control-input ng-pristine ng-untouched ng-valid"
                        checked="checked"
                        style="height: unset !important"
                      />
                      <label
                        style="font-size: 15px"
                        for="gross_partnership"
                        class="custom-control-label mt-1"
                        >Pending Orders</label
                      >
                    </div>
                    <div
                      class="col-lg-2 input-group mb-2 mr-sm-2 custom-control custom-radio d-block float-left"
                    >
                      <Field
                        v-model="order_type"
                        type="radio"
                        value="EXECUTED"
                        id="gross_partnership2"
                        v-on:change="getList()"
                        name="net_gross_partner"
                        class="custom-control-input ng-pristine ng-untouched ng-valid"
                        checked="checked"
                        style="height: unset !important"
                      />
                      <label
                        style="font-size: 15px"
                        for="gross_partnership2"
                        class="custom-control-label mt-1"
                        >Completed Orders</label
                      >
                    </div>
                  </div>

                  <div class="col-lg-2 ml-4 text-right">
                    <button
                      id="btn-search"
                      type="button"
                      v-on:click="getList(1)"
                      class="btn btn-primary mb-2"
                    >
                      Search
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      v-on:click="reset"
                      class="btn btn-secondary mb-2"
                    >
                      Reset
                    </button>
                  </div> -->
              <!-- </div>
              </form> -->
            </div>
          </div>

          <div
            class="row"
            style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
          >
            <div class="col-lg-12 row ml-1">
              <p
                style="
                  color: black;
                  text-align: justify;
                  font-weight: bold;
                  font-size: 12px;
                  margin: 0px !important;
                  margin-top: 7px !important;
                "
              >
                ORDER BOOK
              </p>
              <div class="col-lg-2">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="SEARCH"
                  v-model="keyword"
                  v-on:keyup="getList()"
                />
              </div>
            </div>
            <div
              class="col-md-12 bg-light sticky-table-header"
              style="height: 400px"
            >
              <table
                id="table"
                style="margin-top: 16px"
                data-toggle="table"
                data-search="true"
                data-filter-control="true"
                data-toolbar="#toolbar"
                class="table table-sm text-center text-white table-bordered product-list table-hover"
              >
                <thead>
                  <tr>
                    <th
                      v-if="order_type == 'PENDING'"
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Execute
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      O. Time
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Segment
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Client
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Symbol
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Type
                    </th>

                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Lot
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Qty
                    </th>

                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Order Price
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      Status
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      User
                    </th>
                    <th
                      class="head sorting"
                      id="id-name"
                      v-on:click="sorting('id', 'id-name')"
                    >
                      IP
                    </th>
                    <th class="head" id="id-name">Modify</th>
                    <th class="head" id="id-name">Cancel</th>
                  </tr>
                </thead>
                <tbody v-show="!loader">
                  <tr v-for="(data, index) in list" :key="index">
                    <td
                      v-if="order_type == 'PENDING'"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      <i
                        v-if="data.trade_status == 'PENDING'"
                        class="fa fa-play"
                        v-on:click="executeOrder(data.id, index)"
                        style="cursor: pointer"
                        aria-hidden="true"
                      ></i>
                    </td>

                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      {{
                        data.created_at
                          ? $helperService.getTime(data.created_at)
                          : ""
                      }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      {{
                        data.script
                          ? data.script.exchange == "NFO"
                            ? "NSE"
                            : "MCX"
                          : ""
                      }}
                    </td>
                    <td
                      :title="data.user ? data.user.name : ''"
                      style="text-align: left; cursor: pointer"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      {{
                        data.user
                          ? $helperService.getUpperCase(
                              data.user?.name + "-" + data.user?.name
                            )
                          : ""
                      }}
                    </td>
                    <td
                      style="text-align: left"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      {{ data.trading_symbol ? data.trading_symbol : "" }}
                    </td>
                    <td
                      style="text-align: left"
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      :style="
                        data.order_action == 'BUY'
                          ? 'color: blue;'
                          : 'color: red;'
                      "
                    >
                      {{
                        data.order_type == "MARKET"
                          ? data.order_action
                          : data.order_type == "INTRADAY"
                          ? "Intraday Close"
                          : data.order_action + " " + data.order_type
                      }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      v-if="data.isEdit == false"
                    >
                      {{ data.lot?.toFixed(2) }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      v-if="data.isEdit == true"
                    >
                      <div style="display: block ruby">
                        <Field
                          type="text"
                          class="form-control"
                          name="Lot"
                          style="width: 100px; height: 25px !important"
                          :style="
                            data.script.lot_size == 1 ? 'cursor: no-drop;' : ''
                          "
                          v-model="data.lot"
                          :disabled="data.script.lot_size == 1"
                          v-on:keyup="
                            updateQuantity(
                              data.lot,
                              data.script.lot_size,
                              index
                            )
                          "
                          placeholder="Lot"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                        />
                      </div>
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      v-if="data.isEdit == false"
                    >
                      {{ data.quantity }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      v-if="data.isEdit == true"
                    >
                      <div style="display: block ruby">
                        <Field
                          type="text"
                          class="form-control"
                          name="Qty"
                          style="width: 100px; height: 25px !important"
                          :style="
                            data.script.lot_size != 1 ? 'cursor: no-drop;' : ''
                          "
                          v-model="data.quantity"
                          placeholder="Qty"
                          :disabled="data.script.lot_size != 1"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                        />
                      </div>
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      v-if="data.isEdit == false"
                    >
                      {{ data.price }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      v-if="data.isEdit == true"
                    >
                      <div style="display: block ruby">
                        <Field
                          type="text"
                          style="width: 100px; height: 25px !important"
                          class="form-control"
                          name="Price"
                          placeholder="Price"
                          v-model="data.price"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                        />
                      </div>
                    </td>

                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      {{ data.trade_status }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                      style="text-align: left"
                    >
                        {{
                      data.athority
                        ? $helperService.getUpperCase(data.athority?.code)
                        : ""
                    }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      {{ data.ip }}
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      <i
                        v-if="
                          data.isEdit == false && data.trade_status == 'PENDING'
                        "
                        v-on:click="editRow(index)"
                        class="fa fa-pencil-square-o mr-2"
                        aria-hidden="true"
                        style="cursor: pointer; color: rgb(29, 68, 91)"
                      ></i>
                      <i
                        v-if="data.isEdit == true"
                        v-on:click="updateRow(index)"
                        class="fa fa-check mr-2"
                        aria-hidden="true"
                        style="cursor: pointer; color: green"
                      ></i>
                      <i
                        v-if="data.isEdit == true"
                        v-on:click="closeIsEdit()"
                        class="fa fa-times mr-2"
                        aria-hidden="true"
                        style="cursor: pointer; color: red"
                      ></i>
                    </td>
                    <td
                      :class="
                        data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                      "
                    >
                      <i
                        v-if="data.trade_status == 'PENDING'"
                        v-on:click="showDeletePopup(data)"
                        class="fa fa-trash-o"
                        aria-hidden="true"
                        style="cursor: pointer; color: red"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sds-modal-background"></div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(0)"
      ref="deleteItem"
    />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
// import Datepicker from "vue3-datepicker";
export default {
  name: "TextModal",
  components: {
    Field,
    DeleteModal,
    ErrorComponent,
    // Datepicker,
  },
  data() {
    return {
      id: "",
      isShowModal: false,
      loader: true,
      list: [],
      fromDate: "",
      order_type: "PENDING",
      toDate: "",
      keyword: "",
      type: "",
    };
  },
  mounted() {},
  methods: {
    updateQuantity(lot, lot_size, index) {
      var qty = lot * lot_size;
      for (var i in this.list) {
        if (i == index) {
          this.list[i].quantity = qty;
        }
      }
    },
    editRow(index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].isEdit = true;
        } else {
          this.list[i].isEdit = false;
        }
      }
    },
    closeIsEdit() {
      for (var i in this.list) {
        this.list[i].isEdit = false;
      }
    },
    updateRow(index) {
      for (var i in this.list) {
        if (index == i) {
          this.list[i].isEdit = false;
          var obj = this.list[i];
        }
      }
      this.$api
        .postAPI({
          _action: "/update-trade",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    executeOrder(id, index) {
      var obj = {};
      obj.id = id;
      this.$api
        .postAPI({
          _action: "/update-trade-status",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.list.splice(index, 1);
        })
        .catch(() => {});
    },
    getList() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }
      if (this.id) {
        searchObj.user_id = this.id;
      }
      if (this.order_type) {
        searchObj.trade_status = this.order_type;
      }
      searchObj.order_type = "LIMIT,STOPLOSS,INTRADAY";
      searchObj.day = "TODAY";
      this.$api
        .getAPI({
          _action: "/trade-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          for (var i in this.list) {
            this.list[i].isEdit = false;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.getList();
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Order",
        "Are you sure  want to delete order",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/trade/" + detail.id);
    },
    showModal(id) {
      this.isShowModal = true;
      this.id = id;
      this.getList();
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
