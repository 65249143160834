<template>
  <div v-show="false">
    <div id="printablePrint">
      <page>
        <div class="col-12 row" style="margin-bottom: 10px;  padding-left: 0px; padding-right: 0px; margin-left: 1%">
          <div  style="font-size: 12px; width: 24%;">
            {{ currentTime }}
          </div>
          <div class="text-center" style="font-size: 20px; ; width: 50%;">
            {{ name }}
          </div>
          <div class="text-right" style="font-size: 12px; padding-right: 0px; ; width: 25%;">
            {{ week }}
          </div>
        </div>

        <div v-for="(obj, i) in list" :key="i">
          <div class="bodyclass print">
            <div style="border: black solid 2px">
              <div style="
                color: #000;
                margin-top: 3px;
                margin-bottom: 3px;
                text-align: center;
                font-size: 11px;
                font-weight: 600;
              ">
                {{ obj?.trading_symbol }}
              </div>
              <hr style="margin-top: 0px; margin-bottom: -10px" />
              <div style="padding: 10px">
                <table width="100%" cellpadding="0" cellspacing="0" id="t1">
                  <tr>
                    <th class="head" style="padding-left: 3px; padding-bottom: 5px;">Date Time</th>
                    <th class="head" style="padding-bottom: 5px;">Type</th>
                    <th class="head" style="padding-bottom: 5px;">Lot</th>
                    <th class="head" style="padding-bottom: 5px;">Buy Qty</th>
                    <th class="head" style="padding-bottom: 5px;">Sell Qty</th>
                    <th class="head" style="padding-bottom: 5px;">Rate</th>
                    <th class="head" style="padding-bottom: 5px;">Net Rate</th>
                    <th class="head" style="padding-bottom: 5px;">Amount</th>
                  </tr>
                  <tbody>
                    <tr v-for="(data, index) in obj?.list" :key="index" style="padding-bottom: 2px;">
                      <td colspan="3" style="padding-left: 3px; padding-bottom: 5px;" v-if="data.datatype == 'Total'"
                        class="total">
                        {{ data.title }}
                      </td>
                      <td v-if="data.datatype != 'Total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                        {{ $helperService.getDateTime(data.trade_date) }}
                      </td>
                      <td v-if="data.datatype != 'Total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                        {{ data.type ? data.type : "" }}
                      </td>

                      <td v-if="data.datatype != 'Total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                        {{ data.lot ? data.lot.toFixed(2) : "" }}
                      </td>

                      <td v-if="data.datatype != 'Total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                        <span style="color: blue">
                          {{
                            data.quantity && data.order_action == "BUY"
                            ? data.quantity
                            : "-"
                          }}
                        </span>
                      </td>
                      <td v-if="data.datatype != 'Total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''" class="body">
                        <span style="color: red">
                          {{
                            data.quantity && data.order_action == "SELL"
                            ? data.quantity
                            : "-"
                          }}
                        </span>
                      </td>

                      <td v-if="data.datatype == 'Total'" style="padding-bottom: 5px;" class="total">
                        {{ data.bq }}
                      </td>
                      <td v-if="data.datatype == 'Total'" style="padding-bottom: 5px;" class="total">
                        {{ data.sq }}
                      </td>

                      <td :class="data.datatype != 'Total' ? 'body' : 'total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''">
                        {{
                          data.price
                          ? $helperService.getFormattedPrice(data.price, 0)
                          : 0
                        }}
                      </td>
                      <td :class="data.datatype != 'Total' ? 'body' : 'total'" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''">
                        {{
                          data.net_price
                          ? $helperService.getFormattedPrice(data.net_price, 0)
                          : 0
                        }}
                      </td>

                      <td v-if="data.datatype != 'Total'" class="body" style="padding-bottom: 5px;"
                        :style="data.type == 'BF' || data.type == 'CF' ? 'background:#ffffb3' : ''">
                        <span :style="data.order_action == 'SELL'
                          ? 'color: red'
                          : 'color: blue'
                          ">
                          {{
                            data.order_action == "BUY"
                            ? $helperService.getFormattedPrice(
                              data.net_price * data.quantity
                            )
                            : "-" +
                            $helperService.getFormattedPrice(
                              data.net_price * data.quantity
                            )
                          }}
                        </span>
                      </td>

                      <td v-if="data.datatype == 'Total'" class="total" style="padding-bottom: 5px;">
                        {{ $helperService.getFormattedPrice(data.netamount, 0) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>

        <div class="col-12 row" style="margin-top: -1px; margin-left: 2px;padding: 0px;">
          <div class="col-6 border-1 row">
            <div class="col-6">
              <b style="font-size: 10px;"> Buy Turnover: <span style="color: blue">{{buyTotal
                      ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(buyTotal)
                        )
                      : 0 }}</span> </b>
            </div>
            <div class="col-6">
              <b style="font-size: 10px;"> Sell Turnover: <span style="color: red;">{{sellTotal
                      ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(sellTotal)
                        )
                      : 0  }}</span>
              </b>
            </div>
          </div>
          <div class="col-6 border-1 row">
            <div class="col-6">
              <b style="font-size: 10px;"> Total Gross: <span :style="grossTotal > 0 ? 'color: blue' : 'color: red;'">{{
                grossTotal
                      ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(grossTotal)
                        )
                      : 0
              }}</span>
              </b>
            </div>
            <div class="col-6">
              <b style="font-size: 10px;"> Final Bill Amt: <span
                  :style="finalTotal > 0 ? 'color: blue' : 'color: red;'">{{
                    finalTotal
                      ? $helperService.getThousandConverter(
                          $helperService.getFormattedPrice(finalTotal)
                        )
                      : 0
                      }}</span>
              </b>
            </div>
          </div>
        </div>
      </page>
    </div>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import moment from "moment";

export default {
  name: "PrintSettlement",
  data() {
    return {
      list: [],
      grossTotal: 0,
      finalTotal: 0,
      sellTotal: 0,
      buyTotal: 0,
      currentTime: moment(new Date()).format("DD/MM/YYYY HH:mm"),
      name: '',
      week: ''
    };
  },
  computed: {},
  mounted() { },
  methods: {
    setDataAndPrint(list, name, week) {
      this.grossTotal = 0;
      this.finalTotal = 0;
      this.sellTotal = 0;
      this.buyTotal = 0;
      this.name = name;
      this.week = week;
      for (var i in list) {
        for (var j in list[i].list) {
          if (list[i].list[j].order_action == "BUY") {
            this.buyTotal += list[i].list[j].price * list[i].list[j].quantity;
          } else if (list[i].list[j].order_action == "SELL") {
            this.sellTotal += list[i].list[j].price * list[i].list[j].quantity;
          }
        }

        this.grossTotal += list[i].price;
        this.finalTotal += list[i].netamount;
      }
      this.list = list;
      html2pdf(document.getElementById("printablePrint"), {
        margin: [15, 10, 15, 10],
        image: { type: 'jpeg', quality: 1 },
        filename: this.name + ".pdf",
        html2canvas: {
          dpi: 192,
          scale: this.list.length > 40 ? 1 : 4,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: {
                        mode: ['avoid-all', 'css', 'legacy']
                    }
      });
    },
  },
};
</script>
