<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin100 mt-0">
        <h2 class="text-start" style="color: rgb(29, 68, 91); font-weight: 600">
          MENU ACCESS
        </h2>
        <div class="container row">
          <div class="col-2" style="text-align: initial">
            <input
              id="all_access"
              name="all_access"
              type="checkbox"
              v-model="details.is_all"
              class=""
              v-on:change="checkAll(details.is_all)"
              :true-value="1"
              :false-value="0" />
            <label
              class="col-form-label ml-2"
              style="font-size: 11px; color: gray !important">
              CHECK ALL
            </label>
          </div>
          <Form
            ref="account"
            @submit="save()"
            style="color: black; letter-spacing: 1px">
            <div class="col-lg-12 row">
              <div
                class="col-lg-7 col-xs-12 row"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 2px;
                ">
                <div
                  class="col-lg-12 col-xs-12 text-left"
                  style="text-align: initial">
                  <input
                    id="is_homes"
                    name="is_homes"
                    type="checkbox"
                    v-model="details.is_homes"
                    class=""
                    v-on:change="checkAllHome(details.is_homes)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 15px !important">
                    HOME
                  </label>
                </div>
                 <div class="col-3" style="text-align: initial">
                  <input
                    id="is_home"
                    name="is_home"
                    type="checkbox"
                    v-model="details.is_home"
                    :disabled="!super_master_access.is_home"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    DASHBOARD
                  </label>
                </div>
                <div class="col-3" style="text-align: initial">
                  <input
                    id="is_summary"
                    name="is_summary"
                    type="checkbox"
                    v-model="details.is_summary"
                    :disabled="!super_master_access.is_summary"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    SUMMARY
                  </label>
                </div>
                <div class="col-4" style="text-align: initial">
                  <input
                    id="is_market_watch"
                    name="is_market_watch"
                    type="checkbox"
                    v-model="details.is_market_watch"
                    :disabled="!super_master_access.is_market_watch"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    MARTKET WATCH ORDER WINDOW
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_user"
                    name="is_user"
                    type="checkbox"
                    v-model="details.is_user"
                    :disabled="!super_master_access.is_user"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    USER
                  </label>
                </div>
              </div>
              <div
                class="col-lg-4 row ml-4"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 2px;
                ">
                <div class="col-12 text-left" style="text-align: initial">
                  <input
                    id="is_orderBooks"
                    name="is_orderBooks"
                    type="checkbox"
                    v-model="details.is_orderBooks"
                    class=""
                    v-on:change="checkAllOrderBook(details.is_orderBooks)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 15px !important">
                    ORDER BOOK
                  </label>
                </div>
                <div class="col-3" style="text-align: initial">
                  <input
                    id="is_orderEdit"
                    name="is_orderEdit"
                    type="checkbox"
                    v-model="details.is_orderEdit"
                    :disabled="!super_master_access.is_orderEdit"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    EDIT
                  </label>
                </div>
                <div class="col-3" style="text-align: initial">
                  <input
                    id="is_orderDelete"
                    name="is_orderDelete"
                    type="checkbox"
                    v-model="details.is_orderDelete"
                    :disabled="!super_master_access.is_orderDelete"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    DELETE
                  </label>
                </div>
                <div class="col-3" style="text-align: initial">
                  <input
                    id="is_orderExecute"
                    name="is_orderExecute"
                    type="checkbox"
                    v-model="details.is_orderExecute"
                    :disabled="!super_master_access.is_orderExecute"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    EXECUTE
                  </label>
                </div>
                <div class="col-3" style="text-align: initial">
                  <input
                    id="is_orderExport"
                    name="is_orderExport"
                    type="checkbox"
                    v-model="details.is_orderExport"
                    :disabled="!super_master_access.is_orderExport"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    EXPORT
                  </label>
                </div>
              </div>
              <div
                class="col-lg-7 col-xs-12 row mt-1"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 5px;
                ">
                <div class="col-12 text-left mt-1" style="text-align: initial">
                  <input
                    id="is_tradeBooks"
                    name="is_tradeBooks"
                    type="checkbox"
                    v-model="details.is_tradeBooks"
                    class=""
                    v-on:change="checkAllTradeBook(details.is_tradeBooks)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 15px !important">
                    TRADE BOOK
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_tradeAdd"
                    name="is_tradeAdd"
                    type="checkbox"
                    v-model="details.is_tradeAdd"
                    :disabled="!super_master_access.is_tradeAdd"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    ADD TRADE
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_tradeEdit"
                    name="is_tradeEdit"
                    type="checkbox"
                    v-model="details.is_tradeEdit"
                    :disabled="!super_master_access.is_tradeEdit"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    EDIT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_tradeDelete"
                    name="is_tradeDelete"
                    type="checkbox"
                    v-model="details.is_tradeDelete"
                    :disabled="!super_master_access.is_tradeDelete"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    DELETE
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_tradeExport"
                    name="is_tradeExport"
                    type="checkbox"
                    v-model="details.is_tradeExport"
                    :disabled="!super_master_access.is_tradeExport"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    EXPORT
                  </label>
                </div>
                <div class="col-3" style="text-align: initial">
                  <input
                    id="is_tradeReverse"
                    name="is_tradeReverse"
                    type="checkbox"
                    v-model="details.is_tradeReverse"
                    :disabled="!super_master_access.is_tradeReverse"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    REVERSE TRADE
                  </label>
                </div>
              </div>
              <div
                class="col-lg-4 col-xs-12 row mt-1 ml-4"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 5px;
                ">
                <div class="col-12 text-left mt-1" style="text-align: initial">
                  <input
                    id="is_positionBooks"
                    name="is_positionBooks"
                    type="checkbox"
                    v-model="details.is_positionBooks"
                    class=""
                    v-on:change="checkAllPositionBook(details.is_positionBooks)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 15px !important">
                    POSITION BOOK
                  </label>
                </div>
                <div class="col-4" style="text-align: initial">
                  <input
                    id="is_rollover"
                    name="is_rollover"
                    type="checkbox"
                    v-model="details.is_rollover"
                    :disabled="!super_master_access.is_rollover"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    ROLLOVER
                  </label>
                </div>
                <div class="col-4" style="text-align: initial">
                  <input
                    id="is_closePosition"
                    name="is_closePosition"
                    type="checkbox"
                    v-model="details.is_closePosition"
                    :disabled="!super_master_access.is_closePosition"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label"
                    style="font-size: 11px; color: gray !important">
                    CLOSE POSITION
                  </label>
                </div>
                <div class="col-4" style="text-align: initial">
                  <input
                    id="is_PositionExport"
                    name="is_PositionExport"
                    type="checkbox"
                    v-model="details.is_PositionExport"
                    :disabled="!super_master_access.is_PositionExport"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    EXPORT
                  </label>
                </div>
              </div>
              <div
                class="col-lg-12 col-xs-12 row mt-1"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 5px;
                ">
                <div class="col-12 text-left mt-1" style="text-align: initial">
                  <input
                    id="is_report"
                    name="is_report"
                    type="checkbox"
                    v-model="details.is_report"
                    class=""
                    v-on:change="checkAllReport(details.is_report)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 15px !important">
                    REPORT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_summaryReport"
                    name="is_summaryReport"
                    type="checkbox"
                    v-model="details.is_summaryReport"
                    :disabled="!super_master_access.is_summaryReport"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    SUMMARY REPORT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_branchReport"
                    name="is_branchReport"
                    type="checkbox"
                    v-model="details.is_branchReport"
                    :disabled="!super_master_access.is_branchReport"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    BRANCH REPORT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_ledgerReport1"
                    name="is_ledgerReport1"
                    type="checkbox"
                    v-model="details.is_ledgerReport"
                    :disabled="!super_master_access.is_ledgerReport"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    LEDGER REPORT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_settlement"
                    name="is_settlement"
                    type="checkbox"
                    v-model="details.is_settlement"
                    :disabled="!super_master_access.is_settlement"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    SETTLEMENT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_accountEditLog"
                    name="is_accountEditLog"
                    type="checkbox"
                    v-model="details.is_accountEditLog"
                    :disabled="!super_master_access.is_accountEditLog"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    ACCOUNT EDIT LOG
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_orderEditLog"
                    name="is_orderEditLog"
                    type="checkbox"
                    v-model="details.is_orderEditLog"
                    :disabled="!super_master_access.is_orderEditLog"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    ORDER/TRADE/DELETE LOG
                  </label>
                </div>
              </div>
              <div
                class="col-lg-12 col-xs-12 row mt-1"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 5px;
                ">
                <div class="col-12 text-left mt-1" style="text-align: initial">
                  <input
                    id="is_setting"
                    name="is_setting"
                    type="checkbox"
                    v-model="details.is_setting"
                    class=""
                    v-on:change="checkAllSetting(details.is_setting)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 15px !important">
                    SETTING
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_quantitySetting"
                    name="is_quantitySetting"
                    type="checkbox"
                    v-model="details.is_quantitySetting"
                    :disabled="!super_master_access.is_quantitySetting"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    QUANTITY SETTING
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_orderLimit"
                    name="is_orderLimit"
                    type="checkbox"
                    v-model="details.is_orderLimit"
                    :disabled="!super_master_access.is_orderLimit"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    ORDER LIMIT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_segmentSetting"
                    name="is_segmentSetting"
                    type="checkbox"
                    v-model="details.is_segmentSetting"
                    :disabled="!super_master_access.is_segmentSetting"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    SEGMENT SETTING
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_blockScript"
                    name="is_blockScript"
                    type="checkbox"
                    v-model="details.is_blockScript"
                    :disabled="!super_master_access.is_blockScript"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    BLOCK SCRIPT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_blockIp"
                    name="is_blockIp"
                    type="checkbox"
                    v-model="details.is_blockIp"
                    :disabled="!super_master_access.is_blockIp"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    BLOCK IP
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_transferSetting"
                    name="is_transferSetting"
                    type="checkbox"
                    v-model="details.is_transferSetting"
                    :disabled="!super_master_access.is_transferSetting"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    TRANSFER SETTING
                  </label>
                </div>
              </div>
              <div
                class="col-lg-12 col-xs-12 row mt-1"
                style="
                  box-shadow: black 9px;
                  border: 2px solid whitesmoke;
                  margin: 1px;
                  padding: 5px;
                ">
                <div class="col-12 text-left mt-1" style="text-align: initial">
                  <input
                    id="is_account"
                    name="is_account"
                    type="checkbox"
                    v-model="details.is_accounts"
                    class=""
                    v-on:change="checkAllAccount(details.is_accounts)"
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 15px !important">
                    MASTER
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_manager"
                    name="is_manager"
                    type="checkbox"
                    v-model="details.is_manager"
                    :disabled="!super_master_access.is_manager"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    MANAGER
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_master"
                    name="is_master"
                    type="checkbox"
                    v-model="details.is_master"
                    :disabled="!super_master_access.is_master"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    MASTER
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_broker"
                    name="is_broker"
                    type="checkbox"
                    v-model="details.is_broker"
                    :disabled="!super_master_access.is_broker"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    BROKER
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_customer"
                    name="is_customer"
                    type="checkbox"
                    v-model="details.is_customer"
                    :disabled="!super_master_access.is_customer"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-2"
                    style="font-size: 11px; color: gray !important">
                    CUSTOMER
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_addAccount"
                    name="is_addAccount"
                    type="checkbox"
                    v-model="details.is_addAccount"
                    :disabled="!super_master_access.is_addAccount"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    ADD ACCOUNT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_checkboxEdit"
                    name="is_checkboxEdit"
                    type="checkbox"
                    v-model="details.is_checkboxEdit"
                    :disabled="!super_master_access.is_checkboxEdit"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    CHECKBOX EDIT
                  </label>
                </div>
                <div class="col-2" style="text-align: initial">
                  <input
                    id="is_ledgerEdit"
                    name="is_ledgerEdit"
                    type="checkbox"
                    v-model="details.is_ledgerEdit"
                    :disabled="!super_master_access.is_ledgerEdit"
                    class=""
                    :true-value="1"
                    :false-value="0" />
                  <label
                    class="col-form-label ml-1"
                    style="font-size: 11px; color: gray !important">
                    LEDGER EDIT
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button class="save-btn px-4 ml-3" type="submit" id="save-button">
                SAVE
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <ErrorComponent @retry="getDetails()" ref="errorComponent" />
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import { Form } from "vee-validate";
// import moment from "moment";

export default {
  components: {
    Form,
    ErrorComponent,
  },

  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      details: {},
      super_master_access: {
        is_home: 1,
        is_summary: 1,
        is_market_watch: 1,
        is_user: 1,
        is_orderEdit: 1,
        is_orderDelete: 1,
        is_orderExecute: 1,
        is_orderExport: 1,
        is_tradeAdd: 1,
        is_tradeEdit: 1,
        is_tradeDelete: 1,
        is_tradeExport: 1,
        is_tradeReverse: 1,
        is_rollover: 1,
        is_closePosition: 1,
        is_positionBook: 1,
        is_PositionExport: 1,
        is_summaryReport: 1,
        is_branchReport: 1,
        is_ledgerReport: 1,
        is_settlement: 1,
        is_accountEditLog: 1,
        is_orderEditLog: 1,
        is_quantitySetting: 1,
        is_orderLimit: 1,
        is_segmentSetting: 1,
        is_blockScript: 1,
        is_blockIp: 1,
        is_transferSetting: 1,
        is_manager: 1,
        is_master: 1,
        is_broker: 1,
        is_customer: 1,
        is_addAccount: 1,
        is_checkboxEdit: 1,
        is_ledgerEdit: 1,
      },
    };
  },
  computed: {
    role() {
      return this.$store.getters.getUser?.role || "";
    },
  },
  mounted() {
    if((this.role == 'SUPER_MASTER' || this.role == 'ADMIN') && this.id) {
      this.getDetails();
    } else {
      this.$router.push("/access-denied");
    }
  },
  methods: {
    checkAll(isAll) {
      if (isAll) {
        this.details.is_user = this.super_master_access.is_user
          ? this.super_master_access.is_user
          : 0;
        this.details.is_accountEditLog = this.super_master_access
          .is_accountEditLog
          ? this.super_master_access.is_accountEditLog
          : 0;
        this.details.is_addAccount = this.super_master_access.is_addAccount
          ? this.super_master_access.is_addAccount
          : 0;
        this.details.is_blockIp = this.super_master_access.is_blockIp
          ? this.super_master_access.is_blockIp
          : 0;
        this.details.is_blockScript = this.super_master_access.is_blockScript
          ? this.super_master_access.is_blockScript
          : 0;
        this.details.is_branchReport = this.super_master_access.is_branchReport
          ? this.super_master_access.is_branchReport
          : 0;
        this.details.is_broker = this.super_master_access.is_broker
          ? this.super_master_access.is_broker
          : 0;
        this.details.is_checkboxEdit = this.super_master_access.is_checkboxEdit
          ? this.super_master_access.is_checkboxEdit
          : 0;
        this.details.is_closePosition = this.super_master_access
          .is_closePosition
          ? this.super_master_access.is_closePosition
          : 0;
        this.details.is_customer = this.super_master_access.is_customer
          ? this.super_master_access.is_customer
          : 0;
        this.details.is_home = this.super_master_access.is_home
          ? this.super_master_access.is_home
          : 0;
        this.details.is_ledgerEdit = this.super_master_access.is_ledgerEdit
          ? this.super_master_access.is_ledgerEdit
          : 0;
        this.details.is_ledgerReport = this.super_master_access.is_ledgerReport
          ? this.super_master_access.is_ledgerReport
          : 0;
        this.details.is_manager = this.super_master_access.is_manager
          ? this.super_master_access.is_manager
          : 0;
        this.details.is_master = this.super_master_access.is_master
          ? this.super_master_access.is_master
          : 0;
        this.details.is_orderBook = this.super_master_access.is_orderBook
          ? this.super_master_access.is_orderBook
          : 0;
        this.details.is_orderDelete = this.super_master_access.is_orderDelete
          ? this.super_master_access.is_orderDelete
          : 0;
        this.details.is_orderEdit = this.super_master_access.is_orderEdit
          ? this.super_master_access.is_orderEdit
          : 0;
        this.details.is_orderEditLog = this.super_master_access.is_orderEditLog
          ? this.super_master_access.is_orderEditLog
          : 0;
        this.details.is_orderExecute = this.super_master_access.is_orderExecute
          ? this.super_master_access.is_orderExecute
          : 0;
        this.details.is_orderExport = this.super_master_access.is_orderExport
          ? this.super_master_access.is_orderExport
          : 0;
        this.details.is_orderLimit = this.super_master_access.is_orderLimit
          ? this.super_master_access.is_orderLimit
          : 0;
        this.details.is_positionBook = this.super_master_access.is_positionBook
          ? this.super_master_access.is_positionBook
          : 0;
        this.details.is_PositionExport = this.super_master_access
          .is_PositionExport
          ? this.super_master_access.is_PositionExport
          : 0;
        this.details.is_quantitySetting = this.super_master_access
          .is_quantitySetting
          ? this.super_master_access.is_quantitySetting
          : 0;
        this.details.is_rollover = this.super_master_access.is_rollover
          ? this.super_master_access.is_rollover
          : 0;
        this.details.is_segmentSetting = this.super_master_access
          .is_segmentSetting
          ? this.super_master_access.is_segmentSetting
          : 0;
        this.details.is_settlement = this.super_master_access.is_settlement
          ? this.super_master_access.is_settlement
          : 0;
        this.details.is_summary = this.super_master_access.is_summary
          ? this.super_master_access.is_summary
          : 0;
        this.details.is_market_watch = this.super_master_access.is_market_watch
          ? this.super_master_access.is_market_watch
          : 0;
        this.details.is_summaryReport = this.super_master_access
          .is_summaryReport
          ? this.super_master_access.is_summaryReport
          : 0;
        this.details.is_tradeAdd = this.super_master_access.is_tradeAdd
          ? this.super_master_access.is_tradeAdd
          : 0;
        this.details.is_tradeBook = this.super_master_access.is_tradeBook
          ? this.super_master_access.is_tradeBook
          : 0;
        this.details.is_tradeDelete = this.super_master_access.is_tradeDelete
          ? this.super_master_access.is_tradeDelete
          : 0;
        this.details.is_tradeEdit = this.super_master_access.is_tradeEdit
          ? this.super_master_access.is_tradeEdit
          : 0;
        this.details.is_tradeExport = this.super_master_access.is_tradeExport
          ? this.super_master_access.is_tradeExport
          : 0;
        this.details.is_tradeReverse = this.super_master_access.is_tradeReverse
          ? this.super_master_access.is_tradeReverse
          : 0;
        this.details.is_transferSetting = this.super_master_access
          .is_transferSetting
          ? this.super_master_access.is_transferSetting
          : 0;
      } else {
        this.details.is_user = 0;
        this.details.is_accountEditLog = 0;
        this.details.is_addAccount = 0;
        this.details.is_blockIp = 0;
        this.details.is_blockScript = 0;
        this.details.is_branchReport = 0;
        this.details.is_broker = 0;
        this.details.is_checkboxEdit = 0;
        this.details.is_closePosition = 0;
        this.details.is_customer = 0;
        this.details.is_home = 0;
        this.details.is_ledgerEdit = 0;
        this.details.is_ledgerReport = 0;
        this.details.is_manager = 0;
        this.details.is_master = 0;
        this.details.is_orderBook = 0;
        this.details.is_orderDelete = 0;
        this.details.is_orderEdit = 0;
        this.details.is_orderEditLog = 0;
        this.details.is_orderExecute = 0;
        this.details.is_orderExport = 0;
        this.details.is_orderLimit = 0;
        this.details.is_positionBook = 0;
        this.details.is_PositionExport = 0;
        this.details.is_quantitySetting = 0;
        this.details.is_rollover = 0;
        this.details.is_segmentSetting = 0;
        this.details.is_settlement = 0;
        this.details.is_summary = 0;
        this.details.is_market_watch = 0;
        this.details.is_summaryReport = 0;
        this.details.is_tradeAdd = 0;
        this.details.is_tradeBook = 0;
        this.details.is_tradeDelete = 0;
        this.details.is_tradeEdit = 0;
        this.details.is_tradeExport = 0;
        this.details.is_tradeReverse = 0;
        this.details.is_transferSetting = 0;
      }
    },
    checkAllHome(isAll) {
      if (isAll) {
        this.details.is_user = this.super_master_access.is_user
          ? this.super_master_access.is_user
          : 0;
        this.details.is_summary = this.super_master_access.is_summary
          ? this.super_master_access.is_summary
          : 0;
        this.details.is_market_watch = this.super_master_access.is_market_watch
          ? this.super_master_access.is_market_watch
          : 0;
      } else {
        this.details.is_user = 0;
        this.details.is_summary = 0;
        this.details.is_market_watch = 0;
      }
    },
    checkAllOrderBook(isAll) {
      if (isAll) {
        this.details.is_orderBook = this.super_master_access.is_orderBook
          ? this.super_master_access.is_orderBook
          : 0;
        this.details.is_orderDelete = this.super_master_access.is_orderDelete
          ? this.super_master_access.is_orderDelete
          : 0;
        this.details.is_orderEdit = this.super_master_access.is_orderEdit
          ? this.super_master_access.is_orderEdit
          : 0;
        this.details.is_orderExecute = this.super_master_access.is_orderExecute
          ? this.super_master_access.is_orderExecute
          : 0;
        this.details.is_orderExport = this.super_master_access.is_orderExport
          ? this.super_master_access.is_orderExport
          : 0;
      } else {
        this.details.is_orderBook = 0;
        this.details.is_orderDelete = 0;
        this.details.is_orderEdit = 0;
        this.details.is_orderExecute = 0;
        this.details.is_orderExport = 0;
      }
    },
    checkAllTradeBook(isAll) {
      if (isAll) {
        this.details.is_tradeAdd = this.super_master_access.is_tradeAdd
          ? this.super_master_access.is_tradeAdd
          : 0;
        this.details.is_tradeBook = this.super_master_access.is_tradeBook
          ? this.super_master_access.is_tradeBook
          : 0;
        this.details.is_tradeDelete = this.super_master_access.is_tradeDelete
          ? this.super_master_access.is_tradeDelete
          : 0;
        this.details.is_tradeEdit = this.super_master_access.is_tradeEdit
          ? this.super_master_access.is_tradeEdit
          : 0;
        this.details.is_tradeExport = this.super_master_access.is_tradeExport
          ? this.super_master_access.is_tradeExport
          : 0;
        this.details.is_tradeReverse = this.super_master_access.is_tradeReverse
          ? this.super_master_access.is_tradeReverse
          : 0;
      } else {
        this.details.is_tradeAdd = 0;
        this.details.is_tradeBook = 0;
        this.details.is_tradeDelete = 0;
        this.details.is_tradeEdit = 0;
        this.details.is_tradeExport = 0;
        this.details.is_tradeReverse = 0;
      }
    },
    checkAllPositionBook(isAll) {
      if (isAll) {
        this.details.is_rollover = this.super_master_access.is_rollover
          ? this.super_master_access.is_rollover
          : 0;
        this.details.is_closePosition = this.super_master_access
          .is_closePosition
          ? this.super_master_access.is_closePosition
          : 0;
        this.details.is_positionBook = this.super_master_access.is_positionBook
          ? this.super_master_access.is_positionBook
          : 0;
        this.details.is_PositionExport = this.super_master_access
          .is_PositionExport
          ? this.super_master_access.is_PositionExport
          : 0;
      } else {
        this.details.is_rollover = 0;
        this.details.is_closePosition = 0;
        this.details.is_positionBook = 0;
        this.details.is_PositionExport = 0;
      }
    },
    checkAllReport(isAll) {
      if (isAll) {
        this.details.is_orderEditLog = this.super_master_access.is_orderEditLog
          ? this.super_master_access.is_orderEditLog
          : 0;
        this.details.is_accountEditLog = this.super_master_access
          .is_accountEditLog
          ? this.super_master_access.is_accountEditLog
          : 0;
        this.details.is_settlement = this.super_master_access.is_settlement
          ? this.super_master_access.is_settlement
          : 0;
        this.details.is_ledgerReport = this.super_master_access.is_ledgerReport
          ? this.super_master_access.is_ledgerReport
          : 0;
        this.details.is_branchReport = this.super_master_access.is_branchReport
          ? this.super_master_access.is_branchReport
          : 0;
        this.details.is_summaryReport = this.super_master_access
          .is_summaryReport
          ? this.super_master_access.is_summaryReport
          : 0;
      } else {
        this.details.is_orderEditLog = 0;
        this.details.is_accountEditLog = 0;
        this.details.is_settlement = 0;
        this.details.is_ledgerReport = 0;
        this.details.is_branchReport = 0;
        this.details.is_summaryReport = 0;
      }
    },
    checkAllSetting(isAll) {
      if (isAll) {
        this.details.is_blockIp = this.super_master_access.is_blockIp
          ? this.super_master_access.is_blockIp
          : 0;
        this.details.is_blockScript = this.super_master_access.is_blockScript
          ? this.super_master_access.is_blockScript
          : 0;
        this.details.is_orderLimit = this.super_master_access.is_orderLimit
          ? this.super_master_access.is_orderLimit
          : 0;
        this.details.is_transferSetting = this.super_master_access
          .is_transferSetting
          ? this.super_master_access.is_transferSetting
          : 0;
        this.details.is_segmentSetting = this.super_master_access
          .is_segmentSetting
          ? this.super_master_access.is_segmentSetting
          : 0;
        this.details.is_quantitySetting = this.super_master_access
          .is_quantitySetting
          ? this.super_master_access.is_quantitySetting
          : 0;
      } else {
        this.details.is_blockIp = 0;
        this.details.is_blockScript = 0;
        this.details.is_orderLimit = 0;
        this.details.is_transferSetting = 0;
        this.details.is_segmentSetting = 0;
        this.details.is_quantitySetting = 0;
      }
    },
    checkAllAccount(isAll) {
      if (isAll) {
        this.details.is_broker = this.super_master_access.is_broker
          ? this.super_master_access.is_broker
          : 0;
        this.details.is_manager = this.super_master_access.is_manager
          ? this.super_master_access.is_manager
          : 0;
        this.details.is_master = this.super_master_access.is_master
          ? this.super_master_access.is_master
          : 0;
        this.details.is_customer = this.super_master_access.is_customer
          ? this.super_master_access.is_customer
          : 0;
        this.details.is_addAccount = this.super_master_access.is_addAccount
          ? this.super_master_access.is_addAccount
          : 0;
        this.details.is_checkboxEdit = this.super_master_access.is_checkboxEdit
          ? this.super_master_access.is_checkboxEdit
          : 0;
        this.details.is_ledgerEdit = this.super_master_access.is_ledgerEdit
          ? this.super_master_access.is_ledgerEdit
          : 0;
      } else {
        this.details.is_broker = 0;
        this.details.is_manager = 0;
        this.details.is_master = 0;
        this.details.is_customer = 0;
        this.details.is_addAccount = 0;
        this.details.is_checkboxEdit = 0;
        this.details.is_ledgerEdit = 0;
      }
    },
    getDetails() {
      if (this.id) {
        this.$refs.errorComponent.updateFormLoader(true);
        var obj = {};
        obj.id = this.id;
        this.$api
          .getAPI({
            _action: "/access-detail",
            _body: obj,
          })
          .then((res) => {
            if (res.result) {
              this.details = res.result;
            }
            if (res.super_master_access) {
              this.super_master_access = res.super_master_access;
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      }
    },
    resetForm() {
      this.$refs.account.resetForm();
    },
    save() {
      if (
        !this.details.is_allow_mcx &&
        !this.details.is_allow_nse &&
        this.details.role == "USER"
      ) {
        this.$notify({
          title: "Success",
          type: "error",
          text: "Please Select Any Segment.",
        });
        return;
      }
      if (this.id) {
        this.details.user_id = this.id;
        this.$api
          .postAPI({
            _action: "/add-user-access",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
  },
};
</script>
