<template>
    <div v-show="isShowModal">
      <div class="sds-modal h-100 justify-content-center">
        <div class="sds-modal-body mx-auto" style="height: 650px !important; width: 500px !important;max-width: 1100px !important;">
            <slot></slot>
        </div>
     </div>
     <div class="sds-modal-background"></div>
  </div>
</template>
<script>
    export default {
        name: "TextModal",
        data() {
            return {
                isShowModal: false
            }
        },
        mounted() {},
        methods: {
            showModal() {
                this.isShowModal = true
            },
            closeModal() {
                this.isShowModal = false
            }
        }
    }
</script>