<template>
  <div class="">
    <div class="container form-signin3">
      <div class="row">
        <div
          class="col-md-12 mt-2"
          style="padding-left: 50px; padding-right: 50px"
        >
          <h2
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600"
          >
            Send Notifications
          </h2>
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 row">
              <div class="col-lg-8 row">
                <div class="input-group mb-2 mr-sm-2">
                  <Field
                    type="text"
                    class="form-control"
                    name="ledger_balance"
                    placeholder="SEARCH"
                    v-model="keyword"
                    v-on:keyup="getList()"
                  />
                </div>
                <div class="input-group mb-2 mr-sm-2">
                  <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div> -->
                  <datepicker
                    v-model="fromDate"
                    :upper-limit="toDate"
                    class="form-control"
                    placeholder="From Date"
                  />
                </div>
                <div class="input-group mb-2 mr-sm-2">
                  <!-- <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div> -->
                  <datepicker
                    v-model="toDate"
                    :lower-limit="fromDate"
                    class="form-control"
                    placeholder="To Date"
                  />
                </div>
              </div>
              <div class="col-lg-4 ml-4 text-right">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2"
                >
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2"
                >
                  Reset
                </button>
                <button
                  v-on:click="$router.push('/add-notification')"
                  id="btn-search"
                  type="button"
                  class="btn btn-primary mb-2 ml-2"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px"
      >
        <div class="col-md-12 bg-light border-page">
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover"
          >
            <thead>
              <tr>
                <th class="head">Sr No.</th>
                <th class="head">Super Master</th>
                <th class="head">Message</th>
                <th class="head">Send Date</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr
                v-for="(data, index) in list"
                :key="index"
                style="cursor: pointer"
              >
                <td
                  class="body"
                  v-on:click="$router.push('/notification/' + data.id)"
                >
                  {{ ($refs.listPagination.currentPage - 1) * 20 + index + 1 }}
                </td>
                <td
                  class="body"
                  v-on:click="$router.push('/notification/' + data.id)"
                >
                  {{
                    data.super_master
                      ? data.super_master.name +
                        "(" +
                        data.super_master.code +
                        ")"
                      : ""
                  }}
                </td>
                <td
                  class="body"
                  v-on:click="$router.push('/notification/' + data.id)"
                >
                  {{ data.description }}
                </td>
                <td
                  class="body"
                  v-on:click="$router.push('/notification/' + data.id)"
                >
                  {{
                    data.created_at
                      ? $helperService.getDateTime(data.created_at)
                      : ""
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import swal from "sweetalert2";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { Field } from "vee-validate";
export default {
  components: {
    ErrorComponent,
    Field,
    Datepicker,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      list: [],

      fromDate: "",
      keyword: "",
      toDate: "",
      type: "",
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    getList(page) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      searchObj.offset = page - 1;
      searchObj.limit = 100;
      this.$api
        .getAPI({
          _action: "/master-message-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          if (page == 1) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 100);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      var type = "";
      if (data.status == "ACTIVE") {
        type = "Inactive";
      }
      if (data.status == "INACTICE") {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " account",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-account-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },

    showLimitPopup(data) {
      var type = "";
      if (data.is_limit == 1) {
        type = "Inactive";
      }
      if (data.is_limit == 0) {
        type = "Active";
      }
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to " + type + " limit Orders",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/update-limit_order-status/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  // this.getList(0);
                }
              })
              .catch(() => {});
          } else {
            this.getList(0);
          }
        });
    },
  },
};
</script>
