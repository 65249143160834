<template>
  <main role="main">
    <div id="root">
      <div class="main-content" style="">
        <div class="header bg-gradient-info py-7 py-lg-8">
          <div class="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"></svg>
          </div>
        </div>
        <div class="mt--8 pb-5 container">
          <div class="justify-content-center row">
            <div class="card card-body">
              <div class="text-center card-title" style="font-weight: bold">
                ~~ RULES &amp; REGULATION ~~
              </div>
              <div class="text-center">
                <a
                  style="color: blue; font-weight: bold; cursor: pointer"
                  v-on:click="goToUrl()"
                  >Easytrade9.com</a
                >
              </div>
              <div class="col-lg-12 row" style="text-transform: uppercase">
                <div class="col-lg-6">
                  <div class="text-left card-body" style="line-height: 20px">
                    <p class="p-2">
                      1. Jobbing and Arbitrage is strickly prohibited in our
                      platform
                    </p>
                    <p class="p-2">
                      2. Cheating and Line and chakri trades are strickly
                      prohibited in our platform
                    </p>
                    <p class="p-2">
                      3. Incase above type of activity found in any account,
                      Only profitable trades will be Reversed and informed to
                      client/master/manager during the market
                    </p>
                    <p class="p-2">
                      4. ANY SCRIPT GO IN CLOSE OUT WILL BE SETTLED BY
                      BUYER/SELLER QUOTATION
                    </p>
                    <p class="p-2">
                      5. IF DIVIDEND DECLARE IN ANY SCRIPT N GIVING EFFECT IN
                      FUTURE OPTION THEN DEBIT OR CREDIT IN ACCOUNT
                    </p>
                    <p class="p-2">
                      6. TRADING TIME FNO 9:16 TO 3.30PM MCX 9:01 TO 23:55 PM
                      ALL LIMIT - STOP LOSS - BUY STOP - SELL STOP CANCEL AT
                      23:55 PM
                    </p>
                    <p class="p-2">7. IN ANY DOUBTFUL TRADE THEN REVERSED.</p>
                    <p class="p-2">
                      8. IF ANY SCRIPT IN THE BAN (NEW POSITION) IT WILL BE
                      DELETED WITHOUT PRIOR INTIMATION
                    </p>
                    <p class="p-2">
                      9. LOCKER / PARKING / STANDING SYSTEM NOT ALLOWED SO DO
                      TRADE REGULARLY, OTHERWISE ACTION WILL BE TAKEN
                    </p>
                    <p class="p-2">
                      10. TRADES WILL BE Reversed IF IN 10 MINUTES TIMING (ITS
                      CALLED SHORT TIME TRADING) IT WILL Reversed AUTOMATICALLY.
                    </p>
                    <p class="p-2">
                      11. BTST TRADES NOT ALLOWED LIKE BEFORE CLOSING MARKET AND
                      SQUARE UP POSITION WHEN NEXT DAY OPEN THE MARKET ITS WILL
                      DELETE AUTOMATICALLY.
                    </p>
                    <p class="p-2">
                      12. Trading will be closed before 1 minute of market
                      close.
                    </p>
                    <p class="p-2">
                      13. ID will be auto square off on ask and bid price.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="text-left card-body" style="line-height: 20px">
                    <p class="p-2">
                      1. हमारे प्लेटफॉर्म में जॉबिंग और आर्बिट्रेज सख्त वर्जित
                      है
                    </p>
                    <p class="p-2">
                      2. हमारे मंच में धोखाधड़ी और लाइन और चक्री व्यापार सख्त
                      वर्जित हैं
                    </p>
                    <p class="p-2">
                      3. किसी भी खाते में उपरोक्त प्रकार की गतिविधि पाए जाने पर,
                      प्रॉफिटेबल ट्रेड पूरे हफ्ते के दौरान रिवर्स कर दिया जायेगा
                      और ग्राहक/मास्टर/प्रबंधक को सूचित किया जाएगा
                    </p>
                    <p class="p-2">
                      4. किसी भी स्क्रिप्ट का बंद होना खरीदार/विक्रेता के उद्धरण
                      द्वारा तय किया जाएगा
                    </p>
                    <p class="p-2">
                      5. यदि लाभांश किसी भी स्क्रिप्ट एन देने में घोषित है
                      भविष्य के विकल्प में प्रभाव फिर खाते में डेबिट या क्रेडिट
                    </p>
                    <p class="p-2">
                      6. ट्रेडिंग समय एफएनओ 9:16 से 3.30 अपराह्न एमसीएक्स 9:01
                      से 23:55 अपराह्न सभी सीमा - स्टॉप लॉस - बाय स्टॉप - सेल
                      स्टॉप कैंसिल 23:55 अपराह्न
                    </p>
                    <p class="p-2">
                      7. किसी भी संदिग्ध व्यापार पाए जाने पर प्रॉफिटेबल ट्रेड्स
                      रिवर्स कर दिए जाएंगे।
                    </p>
                    <p class="p-2">
                      8. यदि प्रतिबंध में कोई स्क्रिप्ट (नई स्थिति) है तो उसे
                      पूर्व सूचना के बिना हटा दिया जाएगा
                    </p>
                    <p class="p-2">
                      9. लॉकर/पार्किंग/स्टैंडिंग सिस्टम की अनुमति नहीं है इसलिए
                      नियमित रूप से व्यापार करें, अन्यथा कार्रवाई की जाएगी
                    </p>
                    <p class="p-2">10. सौदा कम से कम 10 मिनट रहना चाहिए ।</p>
                    <p class="p-2">
                      11. BTST ट्रेड ऑटोमैटिकली डिलीट हो जाएगा । (मार्केट क्लोज
                      होने से पहले बाय और मार्केट खुलते ही सेल)
                    </p>
                    <p class="p-2">
                      12. बाजार खुलने से 1 मिनट पहले कारोबार बंद कर दिया जाएगा
                      बंद करना।
                    </p>
                    <p class="p-2">
                      13. पूछने और बोली मूल्य पर आईडी स्वतः चुकता हो जाएगी।
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "TermsAndConditions",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goToUrl() {
      window.open("https://easytrade9.com/");
    },
  },
};
</script>
