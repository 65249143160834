<template>
  <div>
    <div v-if="showMessage" class="text-center" style="color: white">
      <div class="w-50 d-inline-block">
        <h3 class="mb-3 mt-3">
          <h5 style="color: black; font-size: 15px">{{ title }}</h5>
        </h3>
        <p v-if="subtitle">{{ subtitle }}</p>
        <a v-if="subtitle" @click="onClick()" href="javascript: void(0);"
          >Please Retry</a
        >
      </div>
    </div>

    <!-- <img src="/img/new.gif" height="150" />
    </div>
    <div class="formLoading" v-show="formLoader">
      <div style="text-align: center; margin-top: 22%">
        <img style="position: relative" src="/img/ajax-loader.gif" /> -->
    <div
      class="text-center"
      v-show="listLoader"
      style="background: white; border-radius: 0 0 16px 16px; margin-top: 10%"
    >
      <img src="/img/Rupee coin.gif" height="50" />
    </div>
    <div class="formLoading" v-show="formLoader">
      <div style="text-align: center; margin-top: 22%">
        <img
          style="position: relative"
          src="/img/Rupee coin.gif"
          height="100"
          width="100"
        />
        <!-- <div style="position: relative; color: #28282a">Loading...</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorComponent",
  data() {
    return {
      showMessage: false,
      listLoader: false,
      formLoader: false,
      title: "",
      subTitle: "",
    };
  },
  methods: {
    onClick() {
      this.updateShowMessage();
      this.$emit("retry");
    },
    updateShowMessage() {
      this.showMessage = !this.showMessage;
    },
    setTitle(title) {
      if (title == "null") {
        this.setSubtitle();
      } else if (title != "null") {
        this.showMessage = true;
        this.setSubtitle();
        this.title = title;
      } else {
        this.setSubtitle(true);
        this.title = "Error";
        this.updateShowMessage();
      }
    },
    isShowMessage() {
      return this.showMessage;
    },
    setSubtitle(isShowSubtitle) {
      this.subtitle = isShowSubtitle
        ? "Looks like something went wrong!"
        : undefined;
    },
    updateListLoader(isShowListLoader) {
      if (isShowListLoader) {
        this.showMessage = false;
      }
      this.listLoader = isShowListLoader;
    },
    setData(res, title) {
      if (res) {
        if (!res.list || res.list.length <= 0) {
          this.setTitle(title);
        }
      } else {
        this.setTitle();
      }
      this.updateListLoader(false);
    },
    updateFormLoader(isShowFormLoader) {
      if (isShowFormLoader) {
        this.showMessage = false;
      }
      this.formLoader = isShowFormLoader;
    },
  },
};
</script>
<style>
.formLoading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #000;
  opacity: 0.75;
  z-index: 900;
}
</style>
