<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <ul class="col-12 nav nav-pills mb-3">
            <a
              v-if="this.$store.getters.getAccess?.is_home"
              style="
                font-size: 14px;
                line-height: 12px !important;
                cursor: pointer;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'DASHBOARD' }"
              v-on:click="changeTab('DASHBOARD')">
              <span style="color: black">Dashboard</span></a
            >
            <a
              v-if="this.$store.getters.getAccess?.is_summary"
              style="
                font-size: 14px;
                line-height: 12px !important;
                text-align: center;
                display: inline flow-root list-item;
                width: 106px;
                cursor: pointer;
              "
              class="nav-link"
              :class="{ active: currentTab == 'SUMMARY' }"
              v-on:click="changeTab('SUMMARY')">
              <span style="">Summary</span></a
            >
            <a
              v-if="this.$store.getters.getAccess?.is_user"
              style="
                font-size: 14px;
                line-height: 11px !important;
                cursor: pointer;
                width: 102px !important;
              "
              class="nav-link box-border-tab"
              :class="{ active: currentTab == 'USER' }"
              v-on:click="changeTab('USER')">
              <span style="color: black">USER</span></a
            >
          </ul>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-lg-12 row">
            <div class="col-lg-3 mt-1" v-if="role == 'SUPER_MASTER'">
              <Multiselect
                mode="single"
                trackBy="new_name"
                label="new_name"
                valueProp="id"
                class="register-select"
                placeholder="Master"
                v-model="master_id"
                :options="master_list"
                searchable="true" />
            </div>
            <div
              class="col-lg-3 mt-1"
              v-if="role == 'SUPER_MASTER' || role == 'MASTER'">
              <Multiselect
                mode="single"
                trackBy="new_name"
                label="new_name"
                valueProp="id"
                class="register-select"
                placeholder="Broker"
                v-model="broker_id"
                :options="broker_list"
                searchable="true" />
            </div>
            <div class="col-lg-2 mt-1">
              <div class="input-group mb-2">
                <Field
                  type="text"
                  class="form-control"
                  name="ledger_balance"
                  placeholder="SEARCH"
                  v-model="keyword"
                  v-on:keyup="getList(1)" />
              </div>
            </div>

            <div class="col-lg-2">
              <button
                type="button"
                v-on:click="getList(1)"
                class="btn btn-primary mb-2">
                Search
              </button>
              <button
                type="button"
                v-on:click="getListAll()"
                class="btn btn-warning mb-2 ml-2">
                Get All
              </button>
            </div>
            <!-- <div class="col-lg-2"></div> -->
            <div
              class="col-lg-2"
              style="
                font-weight: bold;
                margin-top: 10px;
                text-align: initial;
                font-size: 13px;
              ">
              Total Live M2M :
              {{ $helperService.getThousandConverter(count.toFixed(2)) }}
            </div>
          </div>
          <table
            id="table"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered-summary product-list table-hover">
            <thead>
              <tr>
                <th
                  class="head sorting"
                  id="id-code"
                  style="text-align: initial"
                  v-on:click="sorting('code', 'id-code')">
                  Code
                </th>
                <th
                  class="head sorting"
                  id="id-name"
                  style="text-align: initial"
                  v-on:click="sorting('name', 'id-name')">
                  Name
                </th>
                <th
                  class="head sorting"
                  id="id-margin"
                  v-on:click="sorting('margin', 'id-margin')">
                  Margin
                </th>
                <th
                  class="head sorting"
                  id="id-total_mcx_used_margin"
                  v-on:click="
                    sorting('total_mcx_used_margin', 'id-total_mcx_used_margin')
                  ">
                  MCX Used
                </th>
                <th
                  class="head sorting"
                  id="id-mcx_bal"
                  v-on:click="sorting('mcx_bal', 'id-mcx_bal')">
                  MCX Bal.
                </th>
                <th
                  class="head sorting"
                  id="id-total_nse_used_margin"
                  v-on:click="
                    sorting('total_nse_used_margin', 'id-total_nse_used_margin')
                  ">
                  NSE Used
                </th>
                <th
                  class="head sorting"
                  id="id-nse_bal"
                  v-on:click="sorting('nse_bal', 'id-nse_bal')">
                  NSE Bal.
                </th>
                <th
                  class="head sorting"
                  id="id-ledger_balance"
                  v-on:click="sorting('ledger_balance', 'id-ledger_balance')">
                  Ledger Bal.
                </th>
                <th
                  class="head sorting"
                  id="id-total-m2m"
                  v-on:click="sorting('total_m2m', 'id-total-m2m')">
                  Live M2M
                </th>
                <th
                  class="head sorting"
                  id="id-pl"
                  v-on:click="sorting('pl', 'id-pl')">
                  Net Profit Loss
                </th>
                <th class="head">Orders</th>
                <th class="head">Trades</th>
                <th class="head">Positions</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td class="body-latest" style="text-align: initial">
                  {{ $helperService.getUpperCase(data.code) }}
                </td>
                <td class="body-latest" style="text-align: initial">
                  {{ $helperService.getUpperCase(data.name) }}
                </td>
                <td
                  class="body-latest"
                  style="text-align: end; cursor: pointer"
                  :title="
                    'MCX MARGIN :' +
                    $helperService.getFormattedPrice(data.mcx_margin) +
                    ' | ' +
                    'NSE MARGIN :' +
                    $helperService.getFormattedPrice(data.nse_margin)
                  ">
                  {{ $helperService.getThousandConverter(data.margin) }}
                </td>
                <td
                  class="body-latest"
                  style="text-align: end; cursor: pointer">
                  {{
                    data.total_mcx_used_margin
                      ? $helperService.getThousandConverter(
                          data.total_mcx_used_margin
                        )
                      : 0
                  }}
                </td>
                <td
                  class="body-latest"
                  style="text-align: end; cursor: pointer">
                  {{
                data.master.is_script_wise != 1 &&  data.mcx_bal
                      ? $helperService.getThousandConverter(data.mcx_bal)
                      : "-"
                  }}
                </td>

                <td
                  class="body-latest"
                  style="text-align: end; cursor: pointer">
                  {{
                    data.total_nse_used_margin
                      ? $helperService.getThousandConverter(
                          data.total_nse_used_margin
                        )
                      : 0
                  }}
                </td>

                <td class="body-latest" style="text-align: end">
                  {{
                   data.master.is_script_wise != 1 && data.nse_bal
                      ? $helperService.getThousandConverter(data.nse_bal)
                      : "-"
                  }}
                </td>
                <td
                  class="body-latest"
                  style="text-align: end; cursor: pointer"
                  v-on:click="updateLedger(data.id, data.ledger_balance)">
                  {{ $helperService.getThousandConverter(data.ledger_balance) }}
                </td>
                <td
                  class="body-latest"
                  :style="
                    data.total_m2m < 0
                      ? 'color: red !important'
                      : 'color: blue !important'
                  "
                  style="text-align: end">
                  {{
                    $helperService.getThousandConverter(
                      data.total_m2m?.toFixed(2)
                    )
                  }}
                </td>
                <td class="body-latest" style="text-align: end">
                  {{
                    data.pl ? $helperService.getThousandConverter(data.pl) : 0
                  }}
                </td>
                <td class="body-latest">
                  <a
                    style="color: #f55943; cursor: pointer"
                    v-on:click="showOrderModel(data.id)">
                    View
                  </a>
                </td>
                <td class="body-latest">
                  <a
                    style="color: #f55943; cursor: pointer"
                    v-on:click="showTradeModel(data.id)">
                    View {{ data.user_id }}
                  </a>
                </td>
                <td class="body-latest">
                  <a
                    style="color: #f55943; cursor: pointer"
                    v-on:click="showPositionModel(data.id)">
                    View
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(1)"
      ref="deleteItem" />
    <TextModal ref="updateLedger">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Update Ledger</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.updateLedger.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="updateLedgerForm" @submit="updateLedgerApi()">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Ledger Balance</label>
            <span class="text-danger">*</span>
            <Field
              type="text"
              name="balance"
              class="form-control"
              placeholder="Ledger Balance"
              v-model="ledger.ledger_balance"
              rules="required"
              :validateOnInput="true" />
            <span
              v-if="ledger.ledger_balance"
              style="
                text-align: left;
                color: black;
                font-size: 13px;
                margin-top: 1%;
                display: flex;
              ">
              {{ $helperService.convert(ledger.ledger_balance) }}</span
            >
            <ErrorMessage name="balance" class="text-danger" />
          </div>
        </div>
        <div class="pb-0 text-center">
          <button class="save-btn" id="update-ledger">Update</button>
        </div>
      </Form>
    </TextModal>
    <TradesModal ref="tradesModal" />
    <PositionModal ref="positionModal" />
    <OrderModal ref="orderModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import TradesModal from "@/components/TradesModal.vue";
import PositionModal from "@/components/PositionModal.vue";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import OrderModal from "@/components/OrderModal.vue";
import TextModal from "@/components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.$refs.orderModal.closeModal();
        that.$refs.tradesModal.closeModal();
        that.$refs.positionModal.closeModal();
        that.keyword = "";
        that.getList(1);
      }
    });
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    ErrorComponent,
    TradesModal,
    PositionModal,
    OrderModal,
    Multiselect,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      currentTab: "SUMMARY",
      role: this.$store.getters.getUser?.role,
      loader: true,
      list: [],
      broker_list: [],
      master_list: [],
      keyword: "",
      master_id: "",
      broker_id: "",
      count: 0,
      ledger: {},
    };
  },
  mounted() {
    this.checkAccess();
    this.getList(1);
    this.getBrokerList();
    this.getMasterList();
  },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (access.is_summary == 0) {
        this.$router.push("/access-denied");
      }
    },
    updateLedger(id, balance) {
      this.ledger = {
        id: id,
        ledger_balance: balance,
      };
      if (this.$store.getters.getAccess?.is_ledgerEdit) {
        this.$refs.updateLedger.showModal();
      }
    },
    updateLedgerApi() {
      this.$api
        .putAPI({
          _action: "/client-ledger-update",
          _body: this.ledger,
          _buttonId: "update-ledger",
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });

          this.$refs.updateLedger.closeModal();
          for (var obj of this.list) {
            if (obj.id == this.ledger.id) {
              obj.ledger_balance = this.ledger.ledger_balance;
            }
          }
        })
        .catch(() => {});
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.broker_list = res;
          for (var i in this.broker_list) {
            this.broker_list[i].new_name =
              this.broker_list[i].name + " (" + this.broker_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + " (" + this.master_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id, type) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      if (type) {
        var obj = this.$helperService.sorting(sortBy, id);
        this.getList(1, obj.order_by, obj.sort_by);
      } else {
        var $this = this;
        setTimeout(() => {
          $this.list = $this.$helperService.localSorting(
            $this.list,
            sortBy,
            id
          );
          $this.$refs.errorComponent.updateFormLoader(false);
          $this.loader = false;
        }, 1000);
      }
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      searchObj.role = "USER";
      searchObj.offset = page - 1;
      searchObj.limit = 20;
      this.$api
        .getAPI({
          _action: "/summary-report-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          var count = 0;
          for (var i in this.list) {
            count = count + Number(this.list[i].total_m2m);
            if(this.list[i].master.is_script_wise == 1)
            {
              this.list[i].margin = this.list[i].ledger_balance;
            } else {
            this.list[i].margin =
              (this.list[i].mcx_margin ? Number(this.list[i].mcx_margin) : 0) +
              (this.list[i].nse_margin ? Number(this.list[i].nse_margin) : 0);
            }

            this.list[i].pl = this.list[i].ledger_balance
              ? this.list[i].total_m2m
                ? this.$helperService.getFormattedPrice(
                    Number(this.list[i].ledger_balance.toFixed(2)) +
                      Number(this.list[i].total_m2m.toFixed(2))
                  )
                : 0
              : this.list[i].total_m2m.toFixed(2)
              ? this.list[i].total_m2m.toFixed(2)
              : 0;

              if(this.list[i].master.is_script_wise == 1)
            {
              this.list[i].mcx_bal =
              this.list[i].balance - this.list[i].total_mcx_used_margin;
            this.list[i].nse_bal =
              this.list[i].balance - this.list[i].total_nse_used_margin;
            } else {
            this.list[i].mcx_bal =
              this.list[i].mcx_margin - this.list[i].total_mcx_used_margin;
            this.list[i].nse_bal =
              this.list[i].nse_margin - this.list[i].total_nse_used_margin;
            }
          }
          this.count = count;
          if (page == 1) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 20);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Date available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.keyword = "";
      this.getList(1);
    },
    showTradeModel(id) {
      this.$refs.tradesModal.showModal(id);
    },
    showOrderModel(id) {
      this.$refs.orderModal.showModal(id);
    },
    showPositionModel(id) {
      this.$refs.positionModal.showModal(id);
    },
    getListAll() {
      this.loader = true;
      var searchObj = {};

      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }

      searchObj.role = "USER";

      this.$refs.errorComponent.updateFormLoader(true);
      this.$refs.listPagination.setTotalCount(0);
      this.$api
        .getAPI({
          _action: "/summary-report-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          var count = 0;
          for (var i in this.list) {
            count = count + Number(this.list[i].total_m2m);

            if(this.list[i].master.is_script_wise == 1)
            {
              this.list[i].margin = this.list[i].balance;
            } else {
            this.list[i].margin =
              (this.list[i].mcx_margin ? Number(this.list[i].mcx_margin) : 0) +
              (this.list[i].nse_margin ? Number(this.list[i].nse_margin) : 0);
            }

            this.list[i].pl = this.list[i].ledger_balance
              ? this.list[i].total_m2m
                ? this.$helperService.getFormattedPrice(
                    Number(this.list[i].ledger_balance.toFixed(2)) +
                      Number(this.list[i].total_m2m.toFixed(2))
                  )
                : 0
              : this.list[i].total_m2m.toFixed(2)
              ? this.list[i].total_m2m.toFixed(2)
              : 0;

              if(this.list[i].master.is_script_wise == 1)
            {
              this.list[i].mcx_bal =
              this.list[i].balance - this.list[i].total_mcx_used_margin;
            this.list[i].nse_bal =
              this.list[i].balance - this.list[i].total_nse_used_margin;
            }else {
            this.list[i].mcx_bal =
              this.list[i].mcx_margin - this.list[i].total_mcx_used_margin;
            this.list[i].nse_bal =
              this.list[i].nse_margin - this.list[i].total_nse_used_margin;
            }
          }
          this.count = count;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              { list: res },
              "No Date available"
            );
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    changeTab(tab) {
      this.currentTab = tab;
      if (tab == "DASHBOARD") {
        this.$router.push("/dashboard");
      }
      if (tab == "USER") {
        this.$router.push("/ip");
      }
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
