<template>
  <div v-if="isReady" >
    <div style="padding-top: 60px; padding-bottom: 50px;" >
      <router-view></router-view>
    </div>
    <Header style="top:0; position: fixed; width: 100%;" />
  </div>
  <div class="formLoading" v-show="!isReady">
      <div style="text-align: center; margin-top: 22%">
        <img
          style="position: relative"
          src="/img/Rupee coin.gif"
          height="100"
          width="100"
          alt="Loading..."
        />
      </div>
    </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: "Main",
  components: {
    Header
  },
  data() {
    return {
      isReady: false,
    };
  },
  mounted() {
    this.getLoginUserDetail();
  },
  methods: {
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          this.$store.commit("setUser", res);
          localStorage.setItem("role", res.role);
          localStorage.setItem("is_edit", res.is_edit);
          this.getAccess();
        })
        .catch(() => {});
    },
    getAccess() {
      this.$api
        .getAPI({
          _action: "/user-access",
        })
        .then((res) => {
          if (res) {
            var token = localStorage.getItem("temp_token");
            if (token) {
              var access = {
                is_summary: 1,
                is_home: 1,
                is_market_watch: 1,
                is_user: 1,
                is_orderEdit: 1,
                is_orderDelete: 1,
                is_orderExecute: 1,
                is_orderExport: 1,
                is_tradeAdd: 1,
                is_tradeEdit: 1,
                is_tradeDelete: 1,
                is_tradeExport: 1,
                is_tradeReverse: 1,
                is_rollover: 1,
                is_closePosition: 1,
                is_positionBook: 1,
                is_PositionExport: 1,
                is_summaryReport: 1,
                is_branchReport: 1,
                is_ledgerReport: 1,
                is_settlement: 1,
                is_accountEditLog: 1,
                is_orderEditLog: 1,
                is_quantitySetting: 1,
                is_orderLimit: 1,
                is_segmentSetting: 1,
                is_blockScript: 1,
                is_blockIp: 1,
                is_transferSetting: 1,
                is_manager: 1,
                is_master: 1,
                is_broker: 1,
                is_customer: 1,
                is_addAccount: 1,
                is_checkboxEdit: 1,
                is_ledgerEdit: 1,
              };
              this.$store.commit("setAccess", access);
              localStorage.setItem("access", JSON.stringify(access));
            } else {
              this.$store.commit("setAccess", res.access);
              localStorage.setItem("access", JSON.stringify(res.access));
            }

            this.isReady = true;
          }
        })
        .catch(() => {});
    },
  },
};
</script>