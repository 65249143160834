<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h2 class="text-start" style="color: rgb(29, 68, 91); font-weight: 600; font-size: 16px">
            Summary Report
          </h2>
          <form class="form-inline transparent-form p10 border-page mt-2">
            <div class="col-lg-12 row">
              <div class="col-lg-3">
                <Multiselect mode="single" trackBy="remark" label="remark" valueProp="remark" class="register-select"
                  placeholder="WEEKS VALAN" v-model="id" :options="weekList" searchable @select="getList()"
                  @clear="getList('TYPE')" />
              </div>
              <div class="col-2" style="text-align: left" v-if="userRole == 'ADMIN'">
                <Multiselect mode="single" trackBy="name" label="name" valueProp="server_code" class="register-select"
                  placeholder="Super Master" v-model="code" :options="supermasterList" searchable />
              </div>
              <div class="col-lg-2" v-if="userRole == 'SUPER_MASTER' || userRole == 'MANAGER'">
                <Multiselect mode="single" trackBy="new_name" label="new_name" valueProp="id" class="register-select"
                  placeholder="MASTER" v-model="master_id" :options="master_list" searchable />
              </div>
              <div class="col-lg-2" v-if="userRole != 'BROKER'">
                <Multiselect mode="single" trackBy="new_name" label="new_name" valueProp="id" class="register-select"
                  placeholder="BROKER" v-model="broker_id" :options="brokerList" searchable />
              </div>
              <div class="col-lg-2">
                <Multiselect mode="single" trackBy="new_name" label="new_name" valueProp="id" class="register-select"
                  placeholder="Client" v-model="user_id" :options="clientList" searchable />
              </div>
              <div class="col-lg-3 col-sm-12 row mt-1 justify-content-end">
                <button id="btn-search" type="button" v-on:click="getList()" class="btn btn-primary mb-2">
                  Search
                </button>
                <button type="button" v-on:click="reset" class="btn btn-secondary mb-2 ml-2">
                  Reset
                </button>
                <button type="button" class="btn btn-primary mb-2 ml-2" v-on:click="downloadExcel()">
                  CSV
                </button>
                <button type="button" class="btn btn-primary mb-2 ml-2" v-on:click="downloadPdf()">
                  PDF
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-12">
            <div style="display: flex; justify-content: end">
              <div class="col-lg-3 col-sm-2 mt-2 col-xl-auto handle-radi row"
                v-if="userRole == 'SUPER_MASTER' || userRole == 'MANAGER'">
                <div class="custom-control col-5 custom-radio mb-1">
                  <input type="radio" value="ALL" id="report_all" name="report_type" v-model="report_type"
                    class="custom-control-input ng-pristine mt-1 ng-untouched ng-valid" checked="checked"
                    style="height: unset !important" />
                  <label style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    " for="report_all" class="custom-control-label lable-text">Summary</label>
                </div>
                <div class="custom-control col-7 custom-radio">
                  <input v-model="report_type" type="radio" value="CONSOLIDATE" id="report_consolidate"
                    name="report_type" class="custom-control-input ng-pristine ng-untouched ng-valid"
                    style="height: unset !important" />
                  <label style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    " for="report_consolidate" class="custom-control-label lable-text">Consolidate</label>
                </div>
              </div>
              <button type="button" class="btn btn-primary mb-2 ml-2" v-on:click="downloadAllBill(0)">
                DOWNLOAD ALL
              </button>
            </div>
          </div>
          <table v-if="report_type == 'ALL'" id="table" style="margin-top: 16px" data-toggle="table" data-search="true"
            data-filter-control="true" data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">SR.NO</th>
                <th class="head sorting" id="sort-client-name" v-on:click="sorting('name', 'sort-client-name')">
                  CLIENT NAME
                </th>
                <th class="head">WEEK</th>
                <th class="head sorting" id="sort-gross-mtm" v-on:click="sorting('gross_mtm', 'sort-gross-mtm')">
                  GROSS MTM
                </th>
                <th class="head sorting" id="sort-brokerage" v-on:click="sorting('brokerage', 'sort-gross-mtm')">
                  BROKERAGE
                </th>
                <th class="head sorting" id="sort-net-mtm" v-on:click="sorting('balance', 'sort-net-mtm')">
                  NET MTM
                </th>
                <th class="head sorting" id="sort-downline-mtm" v-on:click="sorting('downline', 'sort-downline-mtm')">
                  Downline MTM
                </th>
                <th class="head sorting" id="sort-upline-mtm" v-on:click="sorting('upline', 'sort-upline-mtm')">
                  Upline MTM
                </th>
                <th class="head sorting" id="sort-self-mtm" v-on:click="sorting('self', 'sort-self-mtm')">
                  Self MTM
                </th>
                <th class="head">PDF</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index" style="cursor: pointer">
                <td class="body" style="
                    text-align: center;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ index + 1 }}
                </td>
                <td class="body" style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.user && data.name
                      ? data.name + " ( " + data.code + " )"
                      : ""
                  }}
                </td>
                <td class="body" style="
                    text-align: center;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ data.remark ? data.remark : "" }}
                </td>
                <td class="body" :style="data.gross_mtm < 0 ? 'color: red' : 'color: blue'" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.gross_mtm
                      ? $helperService.getThousandConverter(
                        $helperService.getFormattedPrice(data.gross_mtm)
                      )
                      : 0
                  }}
                </td>
                <td class="body" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.brokerage
                      ? $helperService.getThousandConverter(
                        $helperService.getFormattedPrice(data.brokerage)
                      )
                      : 0
                  }}
                </td>
                <td class="body" :style="data.balance < 0 ? 'color: red' : 'color: blue'" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.balance
                      ? $helperService.getThousandConverter(
                        $helperService.getFormattedPrice(data.balance)
                      )
                      : 0
                  }}
                </td>

                <td class="body" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      data.downline.toFixed(2)
                    )
                  }}
                </td>

                <td class="body" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(data.upline.toFixed(2))
                    )
                  }}
                </td>

                <td class="body" :style="data.self > 0 ? 'color: red' : 'color: blue'" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(data.self.toFixed(2))
                    )
                  }}
                </td>

                <td class="body" style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  <a style="margin-left: 10px" v-on:click="download(data)"><i class="fa fa-file-pdf-o"
                      aria-hidden="true"></i>
                  </a>
                </td>
              </tr>
              <tr>
                <td class="total" colspan="2"></td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  Total
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(gross_mtm.toFixed(2))
                  }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(brokerage.toFixed(2))
                  }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ $helperService.getThousandConverter(net_mtm.toFixed(2)) }}
                </td>

                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ $helperService.getThousandConverter(downline.toFixed(2)) }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(upline.toFixed(2))
                    )
                  }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(self.toFixed(2))
                    )
                  }}
                </td>
                <td class="total" colspan="2"></td>
              </tr>
            </tbody>
          </table>
          <table v-if="report_type == 'CONSOLIDATE'" id="table" style="margin-top: 16px" data-toggle="table"
            data-search="true" data-filter-control="true" data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">SR.NO</th>
                <th class="head" id="sort-client-name">
                  MASTER NAME
                </th>
                <th class="head">WEEK</th>
                <th class="head" id="sort-gross-mtm">
                  GROSS MTM
                </th>
                <th class="head" id="sort-brokerage">
                  BROKERAGE
                </th>
                <th class="head" id="sort-net-mtm">
                  NET MTM
                </th>
                <th class="head" id="sort-downline-mtm">
                  Downline MTM
                </th>
                <th class="head" id="sort-upline-mtm">
                  Upline MTM
                </th>
                <th class="head" id="sort-self-mtm">
                  Self MTM
                </th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in consolidateList" :key="index" style="cursor: pointer">
                <td class="body" style="
                    text-align: center;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ index + 1 }}
                </td>
                <td class="body" style="
                    text-align: left;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.user && data.master?.name
                      ? data.master?.name + " ( " + data.master?.code + " )"
                      : ""
                  }}
                </td>
                <td class="body" style="
                    text-align: center;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ data.remark ? data.remark : "" }}
                </td>
                <td class="body" :style="data.gross_mtm < 0 ? 'color: red' : 'color: blue'" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.gross_mtm
                      ? $helperService.getThousandConverter(
                        $helperService.getFormattedPrice(data.gross_mtm)
                      )
                      : 0
                  }}
                </td>
                <td class="body" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.brokerage
                      ? $helperService.getThousandConverter(
                        $helperService.getFormattedPrice(data.brokerage)
                      )
                      : 0
                  }}
                </td>
                <td class="body" :style="data.balance < 0 ? 'color: red' : 'color: blue'" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    data.balance
                      ? $helperService.getThousandConverter(
                        $helperService.getFormattedPrice(data.balance)
                      )
                      : 0
                  }}
                </td>

                <td class="body" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      data.downline.toFixed(2)
                    )
                  }}
                </td>

                <td class="body" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(data.upline.toFixed(2))
                    )
                  }}
                </td>

                <td class="body" :style="data.self > 0 ? 'color: red' : 'color: blue'" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(data.self.toFixed(2))
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="total" colspan="2"></td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  Total
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(gross_mtm.toFixed(2))
                  }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(brokerage.toFixed(2))
                  }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ $helperService.getThousandConverter(net_mtm.toFixed(2)) }}
                </td>

                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{ $helperService.getThousandConverter(downline.toFixed(2)) }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(upline.toFixed(2))
                    )
                  }}
                </td>
                <td class="total" style="
                    text-align: right;
                    font-size: 13px !important;
                    font-weight: 600;
                  ">
                  {{
                    $helperService.getThousandConverter(
                      changeValue(self.toFixed(2))
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(0)" ref="errorComponent" />
        </div>
      </div>
    </div>
    <TextModal ref="downloadModel">
      <div class="modal-header pt-0" style="border-bottom: 1px solid lightgrey">
        <h5 class="modal-title">Bill Download Progress</h5>
        <button type="button" class="close" v-on:click="$refs.downloadModel.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <div class="col-12">
          <div style="justify-content: center; display: flex">
            <p class="mt-2">
              <b><span class="text-success">{{ DownloadDone }}</span>/{{ this.list.length }}&nbsp; Completed </b>
            </p>
          </div>
          <div class="col-12 p-1">
            <input type="range" style="width: 100%" min="0" :max="list.length" v-model="DownloadDone"
              placeholder="Start" />
          </div>
        </div>
      </div>
    </TextModal>
    <PrintSettlement ref="printSettlement"></PrintSettlement>
    <PrintSummaryReport ref="printSummaryReport"></PrintSummaryReport>
  </div>
</template>
<script>
// import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import TextModal from "@/components/TextModal.vue";
import ErrorComponent from "@/components/ErrorComponent";
import PrintSettlement from "../../components/PrintSettlement.vue";
import PrintSummaryReport from "../../components/PrintSummaryReport.vue";
export default {
  components: {
    TextModal,
    // Field,
    ErrorComponent,
    PrintSettlement,
    PrintSummaryReport,
    Multiselect,
  },
  name: "TradeSummaryReport",
  data() {
    return {
      loader: true,
      DownloadDone: 0,
      report_type: "ALL",
      consolidateList: [],
      list: [],
      weekList: [],
      live_rates: [],
      client_list: [],
      broker_list: [],
      master_list: [],
      fileHeader:
        "SR.NO,CLIENT NAME,WEEK,Gross MTM,BROKERAGE,NET MTM,DOWNLINE,UPLINE,SELF",
      excel_data: [],
      supermasterList: [],
      id: "",
      code: "",
      user_id: "",
      broker_id: "",
      master_id: "",
      gross_mtm: 0,
      brokerage: 0,
      net_mtm: 0,
      downline: 0,
      upline: 0,
      self: 0,
    };
  },
  computed: {
    userType() {
      return this.$store.getters.getUser?.role == "BROKER"
        ? this.$store.getters.getUser?.broker_type
        : this.$store.getters.getUser?.role;
    },
    userRole() {
      return this.$store.getters.getUser?.role;
    },
    userId() {
      return this.$store.getters.getUser?.role == "SUPER_MASTER"
        ? this.$store.getters.getUser?.server_code
        : this.$store.getters.getUser?.id;
    },
    sharing() {
      return this.$store.getters.getUser?.sharing;
    },
    isGrossSharing() {
      return this.$store.getters.getUser?.is_sharing_gross;
    },
    brokerList() {
      if (this.master_id) {
        var temp = [];
        for (var broker of this.broker_list) {
          if (broker.master_id == this.master_id) {
            temp.push(broker);
          }
        }

        return temp;
      }

      return this.broker_list;
    },
    clientList() {
      var temp = [];
      if (this.master_id && this.broker_id) {
        for (var client of this.client_list) {
          if (
            client.master_id == this.master_id &&
            (this.broker_id == client.broker_id ||
              this.broker_id == client.broker_id_2 ||
              this.broker_id == client.broker_id_3)
          ) {
            temp.push(client);
          }
        }

        return temp;
      } else if (this.master_id) {
        for (var client1 of this.client_list) {
          if (client1.master_id == this.master_id) {
            temp.push(client1);
          }
        }

        return temp;
      } else if (this.broker_id) {
        for (var client2 of this.client_list) {
          if (
            this.broker_id == client2.broker_id ||
            this.broker_id == client2.broker_id_2 ||
            this.broker_id == client2.broker_id_3
          ) {
            temp.push(client2);
          }
        }

        return temp;
      }

      return this.client_list;
    },
  },

  mounted() {
    this.checkAccess();
    this.getSuperMasterList(0);
    this.getWeekList();
    this.getClientList();
    this.getBrokerList();
    this.getMasterList();
    this.getHighLow();
  },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (access.is_summaryReport == 0 && this.userRole != "ADMIN") {
        this.$router.push("/access-denied");
      }
    },
    downloadAllBill() {
      this.$refs.downloadModel.showModal();
      var i = 0;
      var $this = this;
      var timer = setInterval(function () {
        if (i < $this.list.length) {
          $this.downloadV2($this.list[i++]);
        } else {
          $this.$refs.downloadModel.closeModal();
          clearInterval(timer);
        }
      }, 2000);
    },
    downloadV2(data) {
      // if (this.id) {
      //   if (this.id == "Live Report") {
      //     this.downnloadLiveReport(data);
      //     return;
      //   }
      // }
      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {};
      if (this.id) {
        searchObj.id = data.id;
      }
      this.$api
        .getAPI({
          _action: "/user-settle-trades-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;

          if (this.$refs.errorComponent && res.list.length <= 0) {
            // alert("No Data available");
            // this.$refs.errorComponent.updateFormLoader(false);
          } else {
            var tempList = [];
            for (var i in res.list) {
              res.list[i].datatype = "DATA";
              var k = -1;
              for (var j in tempList) {
                if (
                  tempList[j].trading_symbol.toUpperCase() ==
                  res.list[i].trading_symbol.toUpperCase()
                ) {
                  k = j;
                }
              }

              var netamount = res.list[i].net_price * res.list[i].quantity;
              if (res.list[i].order_action == "BUY") {
                netamount = -netamount;
              }

              var net_price = Math.abs(
                res.list[i].net_price * res.list[i].quantity -
                res.list[i].price * res.list[i].quantity
              );
              if (k == -1) {
                var temp = {
                  trading_symbol: res.list[i].trading_symbol,
                  list: [res.list[i]],
                  bq:
                    res.list[i].order_action == "BUY"
                      ? res.list[i].quantity
                      : 0,
                  sq:
                    res.list[i].order_action == "SELL"
                      ? res.list[i].quantity
                      : 0,
                  price:
                    res.list[i].order_action == "BUY"
                      ? -res.list[i].price * res.list[i].quantity
                      : res.list[i].price * res.list[i].quantity,
                  net_price: net_price,
                  netamount: netamount,
                };
                tempList.push(temp);
              } else {
                tempList[k].bq +=
                  res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                tempList[k].sq +=
                  res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                if (res.list[i].order_action == "SELL") {
                  tempList[k].price += res.list[i].price * res.list[i].quantity;
                } else {
                  tempList[k].price -= res.list[i].price * res.list[i].quantity;
                }

                tempList[k].net_price += net_price;
                tempList[k].netamount += netamount;
                tempList[k].list.push(res.list[i]);
              }
            }

            for (var m in tempList) {
              var obj = {
                datatype: "Total",
                title: "Script Wise total:",
                bq: tempList[m].bq,
                sq: tempList[m].sq,
                price: tempList[m].price,
                net_price: Math.round(tempList[m].net_price),
                netamount: tempList[m].netamount,
              };

              tempList[m].list.push(obj);
            }

            var name =
              (res.user_detail.name
                ? this.$helperService.getUpperCase(res.user_detail.name)
                : "") +
              (res.user_detail.code
                ? "(" +
                this.$helperService.getUpperCase(res.user_detail.code) +
                ")"
                : "");

            this.$refs.printSettlement.setDataAndPrint(
              tempList,
              name,
              res.week_Detail?.remark
            );

            this.DownloadDone = this.DownloadDone + 1;
            this.loader = false;
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    async fetchData() {
      return this.excel_data;
    },
    downloadExcel() {
      let filename = "Trades-Summary-Book.csv";
      let text = this.fileHeader;
      for (var j in this.excel_data) {
        text += "\n";
        text += this.excel_data[j];
      }
      let blob = new Blob([text], { type: "text/csv" });
      let link = document.createElement("a");
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = res;
          for (var i in this.client_list) {
            this.client_list[i].new_name =
              this.client_list[i].name + "(" + this.client_list[i].code + ")";
          }
        })
        .catch(() => { });
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.broker_list = res;
          for (var i in this.broker_list) {
            this.broker_list[i].new_name =
              this.broker_list[i].name + "(" + this.broker_list[i].code + ")";
          }
        })
        .catch(() => { });
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + "(" + this.master_list[i].code + ")";
          }
        })
        .catch(() => { });
    },

    getWeekList() {
      this.$api
        .getAPI({
          _action: "/all-week-list",
        })
        .then((res) => {
          this.weekList.push({ remark: "Live Report" });
          for (var obj of res.list) {
            this.weekList.push(obj);
          }
        })
        .catch(() => { });
    },

    getLiveList() {
      this.net_mtm = 0;
      this.gross_mtm = 0;
      this.brokerage = 0;
      this.downline = 0;
      this.upline = 0;
      this.self = 0;

      var searchObj = {
        user_id: this.userId,
        role: this.userRole,
      };
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }

      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }

      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }

      if (this.user_id) {
        searchObj.client_id = this.user_id;
      }

      this.loader = true;
      this.$api
        .getAPI({
          _action: "/live-summary",
          _body: searchObj,
          _isExternal: true,
        })
        .then((res) => {
          this.loader = false;
          this.list = [];
          this.consolidateList = [];
          if (this.$refs.errorComponent && res.length <= 0) {
            // this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            this.$refs.errorComponent.setData(res, "");
            for (var i in res) {
              var is_sharing_gross = false;
              var sharing = 0;
              res[i].user = { name: res[i].name, code: res[i].code };
              res[i].downline = 0;
              res[i].upline = 0;

              if (this.userType == "B1") {
                if (res[i].b2_id) {
                  for (var b2 of this.broker_list) {
                    if (b2.id == res[i].b2_id) {
                      is_sharing_gross = b2.is_sharing_gross;
                      sharing = b2.sharing;
                    }
                  }

                  if (is_sharing_gross == 1) {
                    res[i].downline = (res[i].gross_mtm * sharing) / 100;
                  } else {
                    res[i].downline = (res[i].balance * sharing) / 100;
                  }
                }

                if (res[i].b3_id) {
                  for (var b3 of this.broker_list) {
                    if (b3.id == res[i].b3_id) {
                      is_sharing_gross = b3.is_sharing_gross;
                      sharing = b3.sharing;
                    }
                  }

                  if (is_sharing_gross == 1) {
                    res[i].downline += (res[i].gross_mtm * sharing) / 100;
                  } else {
                    res[i].downline += (res[i].balance * sharing) / 100;
                  }
                }

                if (this.sharing) {
                  if (this.isGrossSharing == 1) {
                    res[i].upline = (res[i].gross_mtm * this.sharing) / 100;
                  } else {
                    res[i].upline = (res[i].balance * this.sharing) / 100;
                  }
                }
              } else if (this.userType == "B2") {
                if (this.sharing) {
                  if (this.isGrossSharing == 1) {
                    res[i].upline = (res[i].gross_mtm * this.sharing) / 100;
                  } else {
                    res[i].upline = (res[i].balance * this.sharing) / 100;
                  }
                }
              } else if (this.userType == "B3") {
                if (this.sharing) {
                  if (this.isGrossSharing == 1) {
                    res[i].upline = (res[i].gross_mtm * this.sharing) / 100;
                  } else {
                    res[i].upline = (res[i].balance * this.sharing) / 100;
                  }
                }
              } else if (this.userType == "MASTER") {
                if (res[i].b1_id) {
                  for (var broker of this.broker_list) {
                    if (broker.id == res[i].b1_id) {
                      is_sharing_gross = broker.is_sharing_gross;
                      sharing = broker.sharing;
                    }
                  }

                  if (is_sharing_gross == 1) {
                    res[i].downline = (res[i].gross_mtm * sharing) / 100;
                  } else {
                    res[i].downline = (res[i].balance * sharing) / 100;
                  }
                }

                if (this.sharing) {
                  if (this.isGrossSharing == 1) {
                    res[i].upline = (res[i].gross_mtm * this.sharing) / 100;
                  } else {
                    res[i].upline = (res[i].balance * this.sharing) / 100;
                  }
                }
              } else if (this.userType == "SUPER_MASTER") {
                if (res[i].m_id) {
                  for (var master of this.master_list) {
                    if (master.id == res[i].m_id) {
                      is_sharing_gross = master.is_sharing_gross;
                      sharing = master.sharing;
                    }
                  }

                  if (is_sharing_gross == 1) {
                    res[i].downline = (res[i].gross_mtm * sharing) / 100;
                  } else {
                    res[i].downline = (res[i].balance * sharing) / 100;
                  }
                }

                if (this.sharing) {
                  if (this.isGrossSharing == 1) {
                    res[i].upline = (res[i].gross_mtm * this.sharing) / 100;
                  } else {
                    res[i].upline = (res[i].balance * this.sharing) / 100;
                  }
                }
                // } else if (this.userType == 'ADMIN') {
                //   if (res.list[i].su) {
                //     if (res.list[i].is_su) {
                //       res.list[i].downline = res.list[i].balance * res.list[i].su / 100
                //     } else {
                //       res.list[i].downline = res.list[i].gross_mtm * res.list[i].su / 100
                //     }
                //   }
              }

              // res[i].downline = res[i].balance - res[i].downline;
              res[i].self = res[i].balance - res[i].downline - res[i].upline;
              var userIndex = -1;

              for (var j in this.list) {
                if (this.list[j].id == res[i].id) {
                  userIndex = j;
                }
              }

              this.net_mtm += res[i].balance;
              this.gross_mtm += res[i].gross_mtm;
              this.brokerage += res[i].brokerage;

              this.downline += res[i].downline;
              this.upline += res[i].upline;
              this.self += res[i].self;

              if (userIndex == -1) {
                this.list.push(res[i]);
              } else {
                this.list[userIndex].gross_mtm += res[i].gross_mtm;
                this.list[userIndex].brokerage += res[i].brokerage;
                this.list[userIndex].downline += res[i].downline;
                this.list[userIndex].upline += res[i].upline;
                this.list[userIndex].balance += res[i].balance;
                this.list[userIndex].self += res[i].self;
              }



              var masterIndex = -1;
              for (var r in this.consolidateList) {
                if (this.consolidateList[r].m_id === res[i].m_id) {
                  masterIndex = r;
                }
              }

              if (masterIndex == -1) {
                res[i].master = this.master_list.find(
                  (x) => x.id == res[i].m_id
                );
                this.consolidateList.push({ ...res[i] });
              } else {
                this.consolidateList[masterIndex].gross_mtm += res[i].gross_mtm;
                this.consolidateList[masterIndex].brokerage += res[i].brokerage;
                this.consolidateList[masterIndex].downline += res[i].downline;
                this.consolidateList[masterIndex].upline += res[i].upline;
                this.consolidateList[masterIndex].balance += res[i].balance;
                this.consolidateList[masterIndex].self += res[i].self;

              }
            }

            this.list &&
              this.list.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              });

            this.$refs.errorComponent.updateFormLoader(false);
          }

          this.excel_data = [];
          if (this.list.length > 0) {
            for (var k in this.list) {
              var obj = "";
              obj += this.list[k].id;
              obj += ",";
              obj += this.list[k].name + " ( " + this.list[k].code + " )";
              obj += ",";
              obj += this.list[k].remark ? this.list[j].remark : "";
              obj += ",";
              obj += this.list[k].gross_mtm
                ? this.$helperService.getFormattedPrice(this.list[k].gross_mtm)
                : 0;
              obj += ",";
              obj += this.list[k].brokerage
                ? this.$helperService.getFormattedPrice(this.list[k].brokerage)
                : 0;
              obj += ",";
              obj += this.list[k].balance
                ? this.$helperService.getFormattedPrice(this.list[k].balance)
                : 0;
              obj += ",";
              obj += this.list[k].downline
                ? this.$helperService.getFormattedPrice(this.list[k].downline)
                : 0;
              obj += ",";
              obj += this.list[k].upline
                ? this.changeValue(
                  this.$helperService.getFormattedPrice(this.list[k].upline)
                )
                : 0;
              obj += ",";
              obj += this.list[k].self
                ? this.changeValue(
                  this.$helperService.getFormattedPrice(this.list[k].self)
                )
                : 0;

              this.excel_data.push(obj);
            }
            var obj_new = "";
            obj_new += "";
            obj_new += ",";
            obj_new += "";
            obj_new += ",";
            obj_new += "Total";
            obj_new += ",";
            obj_new += this.gross_mtm.toFixed(2);
            obj_new += ",";
            obj_new += this.brokerage.toFixed(2);
            obj_new += ",";
            obj_new += this.net_mtm.toFixed(2);
            obj_new += ",";
            obj_new += this.downline.toFixed(2);
            obj_new += ",";
            obj_new += this.changeValue(this.upline.toFixed(2));
            obj_new += ",";
            obj_new += this.changeValue(this.self.toFixed(2));
            this.excel_data.push(obj_new);
          }
        })
        .catch(() => { });
    },
    getList(type) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.net_mtm = 0;
      this.gross_mtm = 0;
      this.brokerage = 0;
      this.downline = 0;
      this.upline = 0;
      this.self = 0;

      var searchObj = {};
      if (this.id) {
        if (this.id == "Live Report") {
          this.getLiveList();
          return;
        }
        searchObj.id = this.id;
      }

      searchObj.role = this.userRole;
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.code) {
        searchObj.code = this.code;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (type) {
        this.$refs.errorComponent.updateFormLoader(false);
        return;
      }
     
      this.list = [];
      this.consolidateList = [];
      this.$api
        .getAPI({
          _action: "/all-user-settlement-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          if (this.$refs.errorComponent && res.list.length <= 0) {
            // this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            this.$refs.errorComponent.setData(res, "");
            for (var i in res.list) {
              res.list[i].balance =
                Number(res.list[i].debit ? res.list[i].debit : 0) +
                Number(res.list[i].credit ? res.list[i].credit : 0);
              this.net_mtm +=
                Number(res.list[i].debit ? res.list[i].debit : 0) +
                Number(res.list[i].credit ? res.list[i].credit : 0);
              this.gross_mtm += Number(res.list[i].gross_mtm);
              res.list[i].brokerage =
                Number(res.list[i].gross_mtm) - Number(res.list[i].balance);
              this.brokerage += res.list[i].brokerage;
              res.list[i].downline = 0;
              res.list[i].upline = 0;

              if (res.list[i].user) {
                res.list[i].name = res.list[i].user.name;
                res.list[i].code = res.list[i].user.code;
              }

              if (this.userType == "B1") {
                if (res.list[i].b2) {
                  if (res.list[i].is_b2) {
                    res.list[i].downline =
                      ((res.list[i].balance - res.list[i].br2) *
                        res.list[i].b2) /
                      100;
                  } else {
                    res.list[i].downline =
                      (res.list[i].gross_mtm * res.list[i].b2) / 100;
                  }
                }

                if (res.list[i].b3) {
                  if (res.list[i].is_b3) {
                    res.list[i].downline +=
                      ((res.list[i].balance - res.list[i].br2) *
                        res.list[i].b3) /
                      100;
                  } else {
                    res.list[i].downline +=
                      (res.list[i].gross_mtm * res.list[i].b3) / 100;
                  }
                }

                if (res.list[i].m) {
                  if (res.list[i].is_m) {
                    res.list[i].upline =
                      ((res.list[i].balance - res.list[i].br1) *
                        res.list[i].m) /
                      100;
                  } else {
                    res.list[i].upline =
                      (res.list[i].gross_mtm * res.list[i].m) / 100;
                  }
                }
              } else if (this.userType == "B2") {
                if (res.list[i].b1) {
                  if (res.list[i].is_b1) {
                    res.list[i].upline =
                      ((res.list[i].balance - res.list[i].br1) *
                        res.list[i].b1) /
                      100;
                  } else {
                    res.list[i].upline =
                      (res.list[i].gross_mtm * res.list[i].b1) / 100;
                  }
                }
              } else if (this.userType == "B3") {
                if (res.list[i].b1) {
                  if (res.list[i].is_b1) {
                    res.list[i].upline =
                      ((res.list[i].balance - res.list[i].br1) *
                        res.list[i].b1) /
                      100;
                  } else {
                    res.list[i].upline =
                      (res.list[i].gross_mtm * res.list[i].b1) / 100;
                  }
                }
              } else if (this.userType == "MASTER") {
                if (res.list[i].b1) {
                  if (res.list[i].is_b1) {
                    res.list[i].downline =
                      ((res.list[i].balance - res.list[i].br2) *
                        res.list[i].b1) /
                      100;
                  } else {
                    res.list[i].downline =
                      (res.list[i].gross_mtm * res.list[i].b1) / 100;
                  }
                }

                if (res.list[i].m) {
                  if (res.list[i].is_m) {
                    res.list[i].upline =
                      ((res.list[i].balance - res.list[i].br1) *
                        res.list[i].m) /
                      100;
                  } else {
                    res.list[i].upline =
                      (res.list[i].gross_mtm * res.list[i].m) / 100;
                  }
                }
              } else if (this.userType == "SUPER_MASTER") {
                if (res.list[i].m) {
                  if (res.list[i].is_m) {
                    res.list[i].downline =
                      ((res.list[i].balance - res.list[i].br2) *
                        res.list[i].m) /
                      100;
                  } else {
                    res.list[i].downline =
                      (res.list[i].gross_mtm * res.list[i].m) / 100;
                  }
                }

                if (res.list[i].su) {
                  if (res.list[i].is_su) {
                    res.list[i].upline =
                      ((res.list[i].balance - res.list[i].br1) *
                        res.list[i].su) /
                      100;
                  } else {
                    res.list[i].upline =
                      (res.list[i].gross_mtm * res.list[i].su) / 100;
                  }
                }
              } else if (this.userType == "ADMIN") {
                if (res.list[i].su) {
                  if (res.list[i].is_su) {
                    res.list[i].downline =
                      (res.list[i].balance * res.list[i].su) / 100;
                  } else {
                    res.list[i].downline =
                      (res.list[i].gross_mtm * res.list[i].su) / 100;
                  }
                }
              }

              // res.list[i].downline = res.list[i].balance - res.list[i].downline;
              res.list[i].self =
                res.list[i].balance - res.list[i].downline - res.list[i].upline;

              this.downline += res.list[i].downline;
              this.upline += res.list[i].upline;
              this.self += res.list[i].self;


              var masterIndex = -1;
              for (var r in this.consolidateList) {
                if (this.consolidateList[r].user.master_id === res.list[i].user.master_id) {
                  masterIndex = r;
                }
              }

              if (masterIndex == -1) {
                res.list[i].master = this.master_list.find(
                  (x) => x.id == res.list[i].user.master_id
                );

                this.consolidateList.push({ ...res.list[i] });
              } else {
                this.consolidateList[masterIndex].gross_mtm += res.list[i].gross_mtm;
                this.consolidateList[masterIndex].brokerage += res.list[i].brokerage;
                this.consolidateList[masterIndex].downline += res.list[i].downline;
                this.consolidateList[masterIndex].upline += res.list[i].upline;
                this.consolidateList[masterIndex].balance += res.list[i].balance;
                this.consolidateList[masterIndex].self += res.list[i].self;
              }
            }
            this.$refs.errorComponent.updateFormLoader(false);
            res.list &&
              res.list.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                  return 1;
                }
                return 0;
              });

            this.list = res.list;

            this.excel_data = [];
            if (res.list.length > 0) {
              for (var j in res.list) {
                var obj = "";
                obj += res.list[j].id;
                obj += ",";
                obj += res.list[j].name
                  ? res.list[j].name + " ( " + res.list[j].code + " )"
                  : "";
                obj += ",";
                obj += res.list[j].remark ? res.list[j].remark : "";
                obj += ",";
                obj += res.list[j].gross_mtm
                  ? this.$helperService.getFormattedPrice(res.list[j].gross_mtm)
                  : 0;
                obj += ",";
                obj += res.list[j].brokerage
                  ? this.$helperService.getFormattedPrice(res.list[j].brokerage)
                  : 0;
                obj += ",";
                obj += res.list[j].balance
                  ? this.$helperService.getFormattedPrice(res.list[j].balance)
                  : 0;
                obj += ",";
                obj += res.list[j].downline
                  ? this.$helperService.getFormattedPrice(res.list[j].downline)
                  : 0;
                obj += ",";
                obj += res.list[j].upline
                  ? this.changeValue(
                    this.$helperService.getFormattedPrice(res.list[j].upline)
                  )
                  : 0;
                obj += ",";
                obj += res.list[j].self
                  ? this.changeValue(
                    this.$helperService.getFormattedPrice(res.list[j].self)
                  )
                  : 0;

                this.excel_data.push(obj);
              }
              var obj_new = "";
              obj_new += "";
              obj_new += ",";
              obj_new += "";
              obj_new += ",";
              obj_new += "Total";
              obj_new += ",";
              obj_new += this.gross_mtm.toFixed(2);
              obj_new += ",";
              obj_new += this.brokerage.toFixed(2);
              obj_new += ",";
              obj_new += this.net_mtm.toFixed(2);
              obj_new += ",";
              obj_new += this.downline.toFixed(2);
              obj_new += ",";
              obj_new += this.changeValue(this.upline.toFixed(2));
              obj_new += ",";
              obj_new += this.changeValue(this.self.toFixed(2));

              this.excel_data.push(obj_new);
            }
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },

    download(data) {
      if (this.id) {
        if (this.id == "Live Report") {
          this.downnloadLiveReport(data);
          return;
        }
      }

      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {};
      if (this.id) {
        searchObj.id = data.id;
      }
      this.$api
        .getAPI({
          _action: "/user-settle-trades-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;

          if (this.$refs.errorComponent && res.list.length <= 0) {
            alert("No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            var tempList = [];
            for (var i in res.list) {
              res.list[i].datatype = "DATA";
              var k = -1;
              for (var j in tempList) {
                if (
                  tempList[j].trading_symbol.toUpperCase() ==
                  res.list[i].trading_symbol.toUpperCase()
                ) {
                  k = j;
                }
              }

              var netamount = res.list[i].net_price * res.list[i].quantity;
              if (res.list[i].order_action == "BUY") {
                netamount = -netamount;
              }

              var net_price = Math.abs(
                res.list[i].net_price * res.list[i].quantity -
                res.list[i].price * res.list[i].quantity
              );
              if (k == -1) {
                var temp = {
                  trading_symbol: res.list[i].trading_symbol,
                  list: [res.list[i]],
                  bq:
                    res.list[i].order_action == "BUY"
                      ? res.list[i].quantity
                      : 0,
                  sq:
                    res.list[i].order_action == "SELL"
                      ? res.list[i].quantity
                      : 0,
                  price:
                    res.list[i].order_action == "BUY"
                      ? -res.list[i].price * res.list[i].quantity
                      : res.list[i].price * res.list[i].quantity,
                  net_price: net_price,
                  netamount: netamount,
                };
                tempList.push(temp);
              } else {
                tempList[k].bq +=
                  res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                tempList[k].sq +=
                  res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                if (res.list[i].order_action == "SELL") {
                  tempList[k].price += res.list[i].price * res.list[i].quantity;
                } else {
                  tempList[k].price -= res.list[i].price * res.list[i].quantity;
                }

                tempList[k].net_price += net_price;
                tempList[k].netamount += netamount;
                tempList[k].list.push(res.list[i]);
              }
            }

            for (var m in tempList) {
              var obj = {
                datatype: "Total",
                title: "Script Wise total:",
                bq: tempList[m].bq,
                sq: tempList[m].sq,
                price: tempList[m].price,
                net_price: Math.round(tempList[m].net_price),
                netamount: tempList[m].netamount,
              };

              tempList[m].list.push(obj);
            }

            var name =
              (res.user_detail.name
                ? this.$helperService.getUpperCase(res.user_detail.name)
                : "") +
              (res.user_detail.code
                ? "(" +
                this.$helperService.getUpperCase(res.user_detail.code) +
                ")"
                : "");

            this.$refs.printSettlement.setDataAndPrint(
              tempList,
              name,
              res.week_Detail?.remark
            );
            this.loader = false;
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    getHighLow() {
      this.$api
        .getAPI({
          _action: "/market-data",
          _isExternal: true,
        })
        .then((res) => {
          this.live_rates = res;
        })
        .catch(() => { });
    },
    getSuperMasterList() {
      this.$api
        .getAPI({
          _action: "/super-master-list",
        })
        .then((res) => {
          this.supermasterList = res;
          // for (var i in this.supermasterList) {
          //   {
          //     if (this.details.user_id == this.supermasterList[i].id) {
          //       this.user_detail = this.supermasterList[i];
          //     }
          //   }
          // }
        })
        .catch(() => { });
    },
    downnloadLiveReport(data) {
      this.$refs.errorComponent.updateFormLoader(true);
      var searchObj = {
        user_id: data.id,
        trade_status: "EXECUTED",
        order_by: "asc",
        sort_by: "trade_date",
      };
      this.$api
        .getAPI({
          _action: "/trade-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          if (this.$refs.errorComponent && res.length <= 0) {
            alert("No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          } else {
            var tempList = [];
            for (var i in res) {
              res[i].datatype = "DATA";
              var k = -1;
              for (var j in tempList) {
                if (
                  tempList[j].trading_symbol.toUpperCase() ==
                  res[i].trading_symbol.toUpperCase()
                ) {
                  k = j;
                }
              }

              var netamount = res[i].net_price * res[i].quantity;
              if (res[i].order_action == "BUY") {
                netamount = -netamount;
              }

              var net_price = Math.abs(
                res[i].net_price * res[i].quantity -
                res[i].price * res[i].quantity
              );
              if (k == -1) {
                var temp = {
                  trading_symbol: res[i].trading_symbol,
                  instrument_token: res[i].instrument_token,
                  list: [res[i]],
                  bq: res[i].order_action == "BUY" ? res[i].quantity : 0,
                  sq: res[i].order_action == "SELL" ? res[i].quantity : 0,
                  price:
                    res[i].order_action == "BUY"
                      ? -res[i].price * res[i].quantity
                      : res[i].price * res[i].quantity,
                  net_price: net_price,
                  netamount: netamount,
                };
                tempList.push(temp);
              } else {
                tempList[k].bq +=
                  res[i].order_action == "BUY" ? res[i].quantity : 0;
                tempList[k].sq +=
                  res[i].order_action == "SELL" ? res[i].quantity : 0;
                if (res[i].order_action == "SELL") {
                  tempList[k].price += res[i].price * res[i].quantity;
                } else {
                  tempList[k].price -= res[i].price * res[i].quantity;
                }

                tempList[k].net_price += net_price;
                tempList[k].netamount += netamount;
                tempList[k].list.push(res[i]);
              }
            }

            for (var m in tempList) {
              var diff = 0;
              if (tempList[m].bq != tempList[m].sq) {
                if (tempList[m].list && tempList[m].list.length > 0) {
                  var lot = tempList[m].list[0].lot;
                  var quantity = tempList[m].list[0].quantity;
                  var brokerage = Math.abs(
                    tempList[m].list[0].net_price - tempList[m].list[0].price
                  );
                  var lot_size = quantity / lot;
                  var price = 0;
                  for (var l in this.live_rates) {
                    if (tempList[m].instrument_token == this.live_rates[l].t) {
                      price = this.live_rates[l].p;
                    }
                  }
                  // instrument_token
                }

                if (price <= 0) {
                  price = tempList[m].price;
                }

                var type = "SELL";
                if (tempList[m].bq > tempList[m].sq) {
                  diff = tempList[m].bq - tempList[m].sq;
                } else if (tempList[m].sq > tempList[m].bq) {
                  diff = tempList[m].sq - tempList[m].bq;
                  type = "BUY";
                }

                var obj1 = {
                  created_at: new Date(),
                  type: "LIVE",
                  order_action: type,
                  lot: lot_size ? diff / lot_size : 1,
                  quantity: diff,
                  price: price,
                  net_price: price + brokerage,
                  netamount: price * diff,
                };

                tempList[m].list.push(obj1);

                tempList[m].bq +=
                  obj1.order_action == "BUY" ? obj1.quantity : 0;
                tempList[m].sq +=
                  obj1.order_action == "SELL" ? obj1.quantity : 0;

                if (obj1.order_action == "SELL") {
                  tempList[m].price += obj1.price * obj1.quantity;
                } else {
                  tempList[m].price -= obj1.price * obj1.quantity;
                }

                tempList[m].net_price += brokerage * obj1.quantity;
                tempList[m].netamount += obj1.netamount;
              }
              var obj = {
                datatype: "Total",
                title: "Script Wise total:",
                bq: tempList[m].bq,
                sq: tempList[m].sq,
                price: tempList[m].price,
                net_price: Math.round(tempList[m].net_price),
                netamount:
                  tempList[m].price - Math.round(tempList[m].net_price),
              };

              tempList[m].netamount = obj.netamount;
              tempList[m].list.push(obj);
            }

            var name =
              (data.name ? this.$helperService.getUpperCase(data.name) : "") +
              (data.code
                ? "(" + this.$helperService.getUpperCase(data.code) + ")"
                : "");

            this.$refs.printSettlement.setDataAndPrint(
              tempList,
              name,
              data.remark
            );
            this.loader = false;
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },

    sorting(sortBy, id) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.loader = true;
      var $this = this;
      setTimeout(() => {
        $this.list = $this.$helperService.localSorting($this.list, sortBy, id);
        $this.$refs.errorComponent.updateFormLoader(false);
        $this.loader = false;
      }, 1000);
    },

    changeValue(value) {
      if (value <= 0) {
        return Math.abs(value);
      } else {
        return 0 - value;
      }
    },

    openWindow(url) {
      window.open(url, "traget");
    },
    reset() {
      this.id = "";
      this.user_id = "";
      this.broker_id = "";
      this.master_id = "";
      this.gross_mtm = 0.0;
      this.net_mtm = 0.0;
      this.brokerage = 0.0;
      this.getList();
    },
    downloadPdf() {
      this.$refs.printSummaryReport.setDataAndPrint(
        this.list,
        this.gross_mtm,
        this.brokerage,
        this.net_mtm,
        this.downline,
        this.upline,
        this.self
      );
    },
  },
};
</script>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
