<template>
  <div>
    <h1>WEB VIEW</h1>
  </div>
</template>
<script>
import webstomp from "webstomp-client";
export default {
  name: "MARKET-WEB",
  data() {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.connect();
    var $this = this;
    setInterval(() => {
      $this.checkConnection();
    }, 5000);
  },
  methods: {
    checkConnection() {
      if (!this.client || !this.client.connected) {
        console.log("try to reconnect connected");
        this.connect();
      } else {
        console.log("already connected");
      }
    },
    connect() {
      this.client = webstomp.client(this.$api.url, { debug: false });
      this.client.connect(this.$api.username, this.$api.password, () => {
        this.client.subscribe("/exchange/market-data", (message) => {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                message.body
              );
            }
        });
      });
    },
  },
};
</script>
